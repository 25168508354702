import { create } from "zustand";

interface PermitMobileStore {
  permitMobile: boolean;
  setPermitMobile: (permit: boolean) => void;
}

export const usePermitMobileStore = create<PermitMobileStore>((set) => ({
  permitMobile: false,
  setPermitMobile: (permit: boolean) => {
    set({ permitMobile: permit });
  },
}));
