import { Box } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import useAiModules from "src/hooks/useAiModules";
import { AiModuleBox, CustomPromptListGrid } from "../AiModules/AiModuleBox";

export const LibraryPageCustomAIModulesLists = () => {
  const { modules, isLoading } = useAiModules();
  const location = useLocation();

  if (isLoading || !modules) return null;

  const { libraryModules } = modules;

  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category");
  const selectedCategories = category?.split(",");

  const filterForCategory = libraryModules.filter((module) => {
    if (!module.moduleDocData?.category) return false;
    return selectedCategories?.includes(module.moduleDocData?.category);
  });

  return (
    <Box pt="20px">
      <CustomPromptListGrid>
        {filterForCategory?.length > 0
          ? filterForCategory.map((prompt) => {
              return (
                <AiModuleBox
                  key={prompt.uid}
                  moduleData={prompt}
                  isLoading={false}
                  action={
                    prompt.inUserLibrary ? "ALREADY_ADDED_INDICATOR" : "ADD"
                  }
                  actionAlwaysOn={true}
                  actionOnlyOnIcon={true}
                />
              );
            })
          : libraryModules.map((prompt) => {
              return (
                <AiModuleBox
                  key={prompt.uid}
                  moduleData={prompt}
                  isLoading={false}
                  action={
                    prompt.inUserLibrary ? "ALREADY_ADDED_INDICATOR" : "ADD"
                  }
                  actionAlwaysOn={true}
                  actionOnlyOnIcon={true}
                />
              );
            })}
      </CustomPromptListGrid>
    </Box>
  );
};
