import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Flex,
  Icon,
  IconButton,
  Text,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { RiCloseLine } from "react-icons/ri";
import { useContentContext } from "../../Context/ContentContext";
import { CONTENT_TYPE } from "../../data/AddContentModalData";
import ContentComponent from "./AddContentComponent";
import AudioUploadComponent from "./AudioUploadComponent";
import FileUploadComponent from "./FIleUploadComponent";
import ImageUploadComponent from "./ImageUploadComponent";
import LinkComponent from "./LinkComponent";
import ManualInsightComponent from "./ManualnsightComponent";
import { SmartImageUpload } from "./SmartImageUpload";
import TextComponent from "./TextComponent";
import YoutubeComponent from "./YoutubeComponent";

const ContentModal = ({ isOpen, onClose, projectName }) => {
  const cancelRef = useRef();
  const { content, setContent } = useContentContext();
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setContent(CONTENT_TYPE.DEFAULT);
        onClose();
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
    >
      <AlertDialogOverlay w="full" h="full" />
      <AlertDialogContent
        bg="primary"
        color="white"
        width={{ base: "90%", lg: "100%" }}
        // This cause the modal to not remain centered.
        // Not sure why this was done this way, so will leave for now.
        // To be deleted later.
        // position="fixed"
        // top="30%"
        // left="35%"
      >
        <Box p={8}>
          <Flex mb={`${content === CONTENT_TYPE.DEFAULT ? 8 : 0}`}>
            {content !== CONTENT_TYPE.DEFAULT && (
              <Icon
                cursor="pointer"
                mr={1}
                mt={-1}
                ml={-1}
                as={BsArrowLeftShort}
                onClick={() => setContent(CONTENT_TYPE.DEFAULT)}
                color="white"
                boxSize={8}
              />
            )}
            <Text flex="1" fontWeight="black">
              {content.headerTitle || `Add content to ${projectName}`}
            </Text>
            <IconButton
              aria-label="Close Modal"
              size="sm"
              icon={<RiCloseLine />}
              variant="solid"
              bg="#282C3E"
              onClick={() => {
                setContent(CONTENT_TYPE.DEFAULT);
                onClose();
              }}
            />
          </Flex>
          {content === CONTENT_TYPE.DEFAULT && <ContentComponent />}
          {content?.type === CONTENT_TYPE.AUDIO && (
            <AudioUploadComponent
              onClose={() => {
                setContent(CONTENT_TYPE.DEFAULT);
                onClose();
              }}
            />
          )}
          {content?.type === CONTENT_TYPE.MEDIA && <ImageUploadComponent />}
          {content?.type === CONTENT_TYPE.LINK && <LinkComponent />}
          {content?.type === CONTENT_TYPE.YOUTUBE && <YoutubeComponent />}
          {content?.type === CONTENT_TYPE.FILE && (
            <FileUploadComponent
              onClose={() => {
                setContent(CONTENT_TYPE.DEFAULT);
                onClose();
              }}
            />
          )}
          {content?.type === CONTENT_TYPE.MANUAL_INSIGHT && (
            <ManualInsightComponent />
          )}
          {content?.type === CONTENT_TYPE.TEXT && (
            <TextComponent
              onClose={() => {
                setContent(CONTENT_TYPE.DEFAULT);
                onClose();
              }}
            />
          )}
          {content?.type === CONTENT_TYPE.SMARTIMAGE && (
            <SmartImageUpload
              onClose={() => {
                setContent(CONTENT_TYPE.DEFAULT);
                onClose();
              }}
            />
          )}
        </Box>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ContentModal;
