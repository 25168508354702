import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import md5 from "md5";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ResizeTextarea from "react-textarea-autosize";
import { ArticleType, IndexingStatus } from "shared/articleTypes";
import { useAuth } from "../../Auth/AuthProvider";
import { useContentContext } from "../../Context/ContentContext";
import { createArticle } from "../../db/article";

const TextComponent = ({ onClose }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const { projectId } = useParams();
  const { showToaster } = useContentContext();

  // const handleInsightSubmission = () => {
  //   setLoading(true);
  //   const payload = {
  //     projectId,
  //     snippetId: uuidv4(),
  //     snippetType: "manualInsight",
  //     title: title,
  //     text: content,
  //     authorId,
  //     curated: true,
  //   };
  //   createSnippet(payload);
  //   showToaster("Insight added", "Your insight has been added", "success");
  //   setLoading(false);
  //   setTitle("");
  //   setContent("");
  // };

  const handleArticleSubmission = async () => {
    setLoading(true);

    const payload = {
      createdAt: Date.now(),
      createdBy: currentUser.uid,
      type: "plainText" as ArticleType,
      source: "local",
      title: title,
      plainText: content,
      indexingStatus: {
        status: "pending" as IndexingStatus,
      },
    };

    await createArticle({ projectId, articleId: md5(content), payload });
    showToaster("Article added", "Your article has been added", "success");
    setLoading(false);
    setTitle("");
    setContent("");
    onClose();
  };

  return (
    <Box w="full">
      <FormControl>
        <FormLabel my={3} fontSize="sm">
          Title
        </FormLabel>
        <Input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          border="1px solid"
          borderColor="rgba(255, 255, 255, 0.3)"
          bg="#282C3E"
          fontSize="13px"
          _placeholder={{
            color: "#ffffffdd",
            opacity: "0.87",
          }}
        />
      </FormControl>
      <FormControl position="relative" borderRadius="5px">
        <FormLabel my={3} fontSize="sm">
          Content
        </FormLabel>
        <Textarea
          borderRadius="10px"
          color="#ffffffdd"
          fontSize="13px"
          _placeholder={{
            color: "#ffffffdd",
            opacity: "0.87",
          }}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          border="1px solid"
          borderColor="rgba(255, 255, 255, 0.3)"
          placeholder="Add your text here"
          as={ResizeTextarea}
          minH="unset"
          resize="none"
          minRows={10}
          bg="#282C3E"
          maxH="60vh"
        />
      </FormControl>
      <Button
        onClick={handleArticleSubmission}
        isDisabled={!title || !content || loading}
        isLoading={loading}
        bg="#282C3E"
        rounded="full"
        w="full"
        mt={4}
      >
        <Text fontSize="sm">Create</Text>
      </Button>
    </Box>
  );
};

export default TextComponent;
