import React from "react";
import { Route } from "react-router-dom";
import { UserWorkspacesLoader } from "src/Context/WorkspaceBoundary";
import { usePermitMobileStore } from "src/stores/permitMobile";
import { DesktopOnlyCompatibilityNotice } from "../Components/Home/DesktopOnlyCompatibilityNotice";
import { useIsMobileDevice } from "../Components/Utils/userDevice";
import { ArticleContextProvider } from "../Context/ArticleContext";
import { ProjectContextProvider } from "../Context/ProjectContext";

const ProtectedRoute = ({ children, ...rest }) => {
  const isMobile = useIsMobileDevice();
  const { permitMobile } = usePermitMobileStore();

  if (isMobile && !permitMobile) {
    return <DesktopOnlyCompatibilityNotice />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        React.cloneElement(
          <UserWorkspacesLoader>
            <ProjectContextProvider>
              <ArticleContextProvider>{children}</ArticleContextProvider>
            </ProjectContextProvider>
          </UserWorkspacesLoader>,
          props
        )
      }
    />
  );
};

export default ProtectedRoute;
