import { Box, Text, chakra } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "src/Auth/AuthProvider";
import { useTemplateContext } from "../../Context/TemplateContext";
import { getCustomizationData } from "../Utils/customizationUtils";
import { TemplateCard } from "./SmartTemplate/TemplateCard";

const customization = getCustomizationData();

const AssistantTemplateList = () => {
  const {
    handleTemplateSelection,
    templateList,
    createNewTemplate,
    updateTemplateName,
  } = useTemplateContext();
  const { projectId } = useParams();
  const { currentUser } = useAuth();
  const currentUserId = currentUser.uid;
  return (
    <Box bgColor="#FBFBFB" h="full" overflowY="auto">
      {templateList.length ? (
        <Box p={3}>
          {templateList.map((template) => (
            <TemplateCard
              key={template.id}
              handleTemplateSelection={handleTemplateSelection}
              updateTemplateName={updateTemplateName}
              projectId={projectId}
              template={template}
            />
          ))}
        </Box>
      ) : (
        <chakra.div
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <Box textAlign="center">
            <Text color="#191D30" opacity="0.5" fontSize="sm">
              Nothing here yet!
            </Text>
            <Text color="#191D30" fontSize="xs">
              <chakra.span opacity="0.5">
                {`Create your first ${customization.template.name.toLowerCase()} by clicking`}{" "}
              </chakra.span>
              <chakra.span
                cursor="pointer"
                onClick={() => createNewTemplate(currentUserId)}
              >
                {`New ${customization.template.name}`}
              </chakra.span>
            </Text>
          </Box>
        </chakra.div>
      )}
    </Box>
  );
};

export default AssistantTemplateList;
