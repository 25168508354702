import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Link,
  Spacer,
  Stack,
  Text,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import React, { memo, useEffect, useRef, useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { FiEye, FiEyeOff } from "react-icons/fi";
import {
  MdModeComment,
  MdOutlineComment,
  MdOutlineContentCopy,
} from "react-icons/md";
import { AuthorNameAndPhoto } from "src/Components/AttributeAuthorElements/AttributeAuthorElements";
import PermissionWrapper from "src/Components/HigherOrderComponents/PermissionWrapper";
import { useProjectContext } from "src/Context/ProjectContext";
import { PERMISSION_ERROR_MSG } from "src/data/PermissionData";
import { useUserNameAndPhotoUrl } from "src/db/project";
import {
  SNIPPET_TYPE_DATA,
  VIEW_IN_SOURCE_ENABLED,
} from "../../../data/SnippetTypeData";
import { addSnippetComment, deleteSnippetComment } from "../../../db/comment";
import { deleteSnippet } from "../../../db/snippet";
import useInsight from "../../../hooks/useInsight";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { copyInsightCardTextToClipboard } from "../../Utils/insightUtils";
import { ManualInsightSourceList } from "../../assistant/ManualInsightSource/ManualInsightSourceList";
import CommentModal from "../CommentModal";
import { DeleteAlert } from "../DeleteAlert";
import ImageViewer from "../ImageViewer";
import InsightCardContent from "../InsightCardContent";
import DefaultAndCompactCard from "./DefaultAndCompactCard";

const customization = getCustomizationData();

const handleSnippetHover = ({ insight, setActiveSnippet }) => {
  if (!insight || !insight.articleUrl) {
    setActiveSnippet(null);
    return;
  }
  setActiveSnippet(insight.uid);
};

const ShowTextAndIcon = ({ icon, text, handler, isDisabled }) => {
  return (
    <Stack
      cursor={isDisabled ? "not-allowed" : "pointer"}
      onClick={!isDisabled ? handler : null}
      direction={"row"}
      align={"center"}
      title={isDisabled ? PERMISSION_ERROR_MSG : ""}
    >
      <Flex align={"center"} justify={"center"} rounded={"full"}>
        <Icon
          color={isDisabled ? "gray.400" : "gray.600"}
          as={icon}
          w={4}
          h={4}
        />
      </Flex>
      <Text color={isDisabled ? "gray.400" : "gray.600"} fontSize="xs">
        {text}
      </Text>
    </Stack>
  );
};

const ComfortModeImage = ({ insight, onOpen }) => {
  if (insight.type !== "image") return null;
  return (
    <Image
      my={4}
      src={insight.imageUrl}
      fallbackSrc="https://via.placeholder.com/200"
      maxW="80%"
      maxH="150px"
      onClick={onOpen}
      cursor="pointer"
    />
  );
};

const CardActions = ({ insight, handlers }) => {
  const { onComment, onCopy, displayActionButtons } = handlers;

  return (
    <Flex gap="4">
      <ShowTextAndIcon
        isDisabled={false}
        handler={onComment}
        text="Comments"
        icon={insight?.comments?.length > 0 ? MdModeComment : MdOutlineComment}
      />
      <ShowTextAndIcon
        isDisabled={false}
        text="Copy"
        icon={MdOutlineContentCopy}
        handler={onCopy}
      />
      {displayActionButtons(insight.type)}
    </Flex>
  );
};

const InsightCard = ({
  insight,
  articleText,
  projectId,
  viewMode,
  isInsightPage,
  isLastItem,
}) => {
  const { activeInsight, setActiveInsight, updateSnippetCurated } =
    useInsight(projectId);

  const { currentProjectData } = useProjectContext();
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [currentSnippetForCommentModal, setCurrentSnippetForCommentModal] =
    useState();
  const [activeSnippet, setActiveSnippet] = useState();
  const [isExpanded, setIsExpanded] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const viewedInsight = useRef();

  useEffect(() => {
    if (viewedInsight.current) {
      setCurrentSnippetForCommentModal(insight);
    }
  }, [insight]);

  const ViewSourceButton = ({
    type,
    activeInsight,
    insightUid,
    setActiveInsight,
  }) => {
    if (!VIEW_IN_SOURCE_ENABLED.includes(type)) {
      return null;
    }

    const isActive = activeInsight === insightUid;

    return (
      <ShowTextAndIcon
        text={isActive ? "Close" : "View in Source"}
        icon={isActive ? FiEyeOff : FiEye}
        handler={() => setActiveInsight(isActive ? null : insightUid)}
        isDisabled={false}
      />
    );
  };

  // SaveButton component handles the save/unsave functionality
  const SaveButton = ({ insight, updateSnippetCurated }) => {
    return (
      <PermissionWrapper>
        {(hasEditPermission) => (
          <ShowTextAndIcon
            text={insight.curated ? "Unsave" : "Save"}
            icon={insight.curated ? BsBookmarkFill : BsBookmark}
            handler={() => updateSnippetCurated(insight.uid, !insight.curated)}
            isDisabled={!hasEditPermission}
          />
        )}
      </PermissionWrapper>
    );
  };

  // ActionButtons component combines all action buttons
  const ActionButtons = ({
    type,
    insight,
    activeInsight,
    setActiveInsight,
    updateSnippetCurated,
  }) => {
    // Helper function to determine if buttons should be displayed
    const shouldDisplayButtons = () => {
      return !(insight.type === "image" || insight.type === "manualInsight");
    };

    // Don't render anything for inTextLink type
    if (type === "inTextLink") {
      return null;
    }

    return (
      <>
        <ViewSourceButton
          type={type}
          activeInsight={activeInsight}
          insightUid={insight.uid}
          setActiveInsight={setActiveInsight}
        />

        {shouldDisplayButtons() && (
          <SaveButton
            insight={insight}
            updateSnippetCurated={updateSnippetCurated}
          />
        )}
      </>
    );
  };

  // Main display function that uses the components
  const displayActionButtons = (type) => {
    return (
      <ActionButtons
        type={type}
        insight={insight}
        activeInsight={activeInsight}
        setActiveInsight={setActiveInsight}
        updateSnippetCurated={updateSnippetCurated}
      />
    );
  };

  const CardTitle = () => {
    if (insight.title) {
      return insight.title;
    }
    let title = "";
    if (insight.articleTitle) {
      title = insight.articleTitle;
    } else if (insight.articleUrl) {
      title = insight.articleUrl;
    }

    if (title) {
      return `${SNIPPET_TYPE_DATA[insight.type].name} from ${title}`;
    } else {
      return SNIPPET_TYPE_DATA[insight.type].name;
    }
  };

  const {
    data: authorData,
    isLoading: authorLoading,
    error: authorFetchingError,
  } = useUserNameAndPhotoUrl(insight.createdBy);

  const displayViewModeContent = () => {
    if (viewMode !== "COMFORT") {
      return (
        <CardBody p={0} className={isLastItem && "aiInsightSampleCard"}>
          <DefaultAndCompactCard
            viewInSourceHandler={setActiveInsight}
            insight={insight}
            activeInsight={activeInsight}
            commentHandler={() => {
              setCurrentSnippetForCommentModal(insight);
              setShowCommentModal(true);
              viewedInsight.current = insight.uid;
            }}
            saveHandler={updateSnippetCurated}
            mode={viewMode}
            isExpanded={isExpanded}
            isInsightPage={isInsightPage}
            setIsExpanded={setIsExpanded}
            updateSnippetCurated={updateSnippetCurated}
            fullText={articleText}
            insightId={insight.uid}
            projectId={projectId}
          />
        </CardBody>
      );
    }

    return (
      <CardBody>
        <Grid templateColumns="repeat(16, 1fr)" gap={2}>
          <GridItem colSpan={1}>
            <Avatar
              bg={SNIPPET_TYPE_DATA[insight.type]?.color}
              color="black"
              icon={
                <Icon
                  as={SNIPPET_TYPE_DATA[insight.type].icon}
                  fontSize="15px"
                />
              }
              zIndex={insight.sourceArticles?.length + 1}
              w="20px"
              h="20px"
            />
            {insight.type === "assistantInsight" && (
              <ManualInsightSourceList
                iconsLists={insight.sourceArticles}
                iconsToRender={3}
                iconsGaps="-33%"
                counterGap={"-35%"}
                isTemplate={false}
              />
            )}
          </GridItem>
          <GridItem colSpan={15}>
            <Flex>
              <Box flex="1">
                <Link
                  isExternal
                  href={
                    customization.documentMenu.hideOpenInBrowser
                      ? null
                      : insight.articleUrl
                  }
                  _hover={
                    activeSnippet === insight.uid
                      ? {
                          textDecoration: "underline",
                          cursor: "pointer",
                        }
                      : undefined
                  }
                  cursor="auto"
                >
                  <Text
                    color="rgba(0, 0, 0, 0.54)"
                    fontSize="xs"
                    onMouseEnter={() =>
                      handleSnippetHover({ insight, setActiveSnippet })
                    }
                    onMouseLeave={() =>
                      handleSnippetHover({ insight: null, setActiveSnippet })
                    }
                  >
                    <CardTitle />
                    {activeSnippet === insight.uid && (
                      <chakra.span>
                        <Icon mb={-1} as={BiLinkExternal} boxSize={4} />
                      </chakra.span>
                    )}
                  </Text>
                </Link>
                <Box maxH="486px" my={4} overflowY="scroll">
                  <Box display={"block"}>
                    <ComfortModeImage insight={insight} onOpen={onOpen} />
                    <InsightCardContent
                      type={insight.type}
                      activeHighlight={activeInsight}
                      text={insight.text}
                      bold={insight.bold}
                      insightId={insight.uid}
                      fullText={articleText}
                      projectId={projectId}
                    />
                  </Box>
                </Box>
              </Box>
            </Flex>
            <Flex minWidth="max-content" alignItems="center" gap="8">
              {currentProjectData?.data.shared && (
                <Box>
                  <AuthorNameAndPhoto
                    authorData={authorData?.data}
                    authorLoading={authorLoading}
                    authorFetchingError={!!authorFetchingError}
                  />
                </Box>
              )}
              <Spacer />
              <CardActions
                insight={insight}
                handlers={{
                  onComment: () => {
                    setCurrentSnippetForCommentModal(insight);
                    setShowCommentModal(true);
                    viewedInsight.current = insight.uid;
                  },
                  onCopy: () => copyInsightCardTextToClipboard(insight.text),
                  displayActionButtons,
                }}
              />
            </Flex>
          </GridItem>
        </Grid>
      </CardBody>
    );
  };

  return (
    <>
      <CommentModal
        close={() => {
          setShowCommentModal(false);
        }}
        isOpen={showCommentModal}
        content={currentSnippetForCommentModal}
        createFunc={addSnippetComment}
        deleteFunc={deleteSnippetComment}
        rootId={projectId}
      />
      <Card shadow="sm" mb={2} color="primary">
        {displayViewModeContent()}
        <DeleteAlert
          action={() => {
            deleteSnippet({ projectId, snippetId: insight.uid });
            setShowDeleteAlert(false);
          }}
          isOpen={showDeleteAlert}
          onClose={() => setShowDeleteAlert(false)}
          title="Delete Note"
          body="Are you sure you want to delete this article's note?"
        />
      </Card>
      <ImageViewer
        imageUrl={insight.imageUrl}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default memo(InsightCard);
