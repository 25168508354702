import React from "react";
import { MONTHLY_AI_CREDITS_PER_PLAN } from "shared/constants";
import { WorkspacePlanIds } from "shared/workspaceTypes";
import DiamondIcon from "src/Components/Icons/DiamondIcon";
import StartFlagIcon from "src/Components/Icons/StartFlag";

export const AI_CREDIT_PRICES: { [key: number]: number } = {
  100: 1000,
  250: 2000,
  500: 3500,
  1000: 6000,
};

export const AI_STORAGE_PRICES: { [key: number]: number } = {
  15: 1800,
  35: 3500,
  75: 6000,
  150: 10000,
};

export type Perk = {
  value: string;
  compareValue?: boolean | string;
  compareName?:
    | "AI Storage"
    | "Monthly AI Credits"
    | "Projects"
    | "Collaboration"
    | "Data sources"
    | "AI Models"
    | "Module library"
    | "Module editor"
    | "Support";
};

export type Plan = {
  id: string;
  /** Human readable name of the plan*/
  name: string;
  /** For premium plans is the product ID in Stripe */
  productId?: string;
  /** For premium plans is the price ID in Stripe */
  priceId?: string;
  /** For premium plans is the price in USD */
  priceMonthUsd?: number;
  /** Monthly AI credits for this plan */
  monthlyAiCredits: number;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  perks: Perk[];
};

export type PlansList = {
  [key in WorkspacePlanIds]: Plan;
};

export const plansList: PlansList = {
  free: {
    id: "free",
    name: "No plan",
    icon: (props) => <StartFlagIcon {...props} />,
    monthlyAiCredits: MONTHLY_AI_CREDITS_PER_PLAN.free,
    perks: [
      {
        value: "Unlimited Projects",
        compareValue: true,
        compareName: "Projects",
      },
      {
        value: "2,000 pages of AI-ready storage",
        compareValue: "2,000 pages",
        compareName: "AI Storage",
      },
      {
        value: "Projects shareable in view only",
        compareValue: "View-only",
        compareName: "Collaboration",
      },
      {
        value: "100 AI Credits on sign-up",
        compareValue: "0",
        compareName: "Monthly AI Credits",
      },
    ],
  },
  starter: {
    id: "starter",
    name: "Starter",
    productId: process.env.REACT_APP_STARTER_PLAN_PRODUCT_ID,
    priceId: process.env.REACT_APP_STARTER_PLAN_PRICE_MONTHLY_ID,
    priceMonthUsd: 79,
    monthlyAiCredits: MONTHLY_AI_CREDITS_PER_PLAN.starter,
    icon: (props) => <DiamondIcon {...props} />,
    perks: [
      {
        value: "Unlimited Projects",
        compareValue: true,
        compareName: "Projects",
      },
      {
        value: "75,000 pages of AI-ready storage",
        compareValue: "75,000 pages",
        compareName: "AI Storage",
      },
      {
        value: "Fully Collaborative projects",
        compareValue: "Fully Collaborative projects",
        compareName: "Collaboration",
      },
      {
        value: `${MONTHLY_AI_CREDITS_PER_PLAN.starter} AI Credits per month`,
        compareValue: `${MONTHLY_AI_CREDITS_PER_PLAN.starter} every month`,
        compareName: "Monthly AI Credits",
      },
      {
        value: "Integration with Google Drive (coming soon)",
        compareValue: "Google Drive",
        compareName: "Data sources",
      },
    ],
  },
};
