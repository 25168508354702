import React, { useEffect, useRef } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import "../ChatGpt/PromptResult.css";

const ReferenceBubble = ({
  href,
  children,
  chunkInfo,
  chunkToArticleReferenceNumberMap,
}) => {
  const { isPowerUser } = useAuth();
  const SHOW_CHUNK_CONTENT = isPowerUser;
  // Create ref to bubble
  const bubbleRef = useRef(null);

  useEffect(() => {
    /**
     * Adjust the position of the tooltip so that it is not occluded by the right side of the container.
     *
     * @returns {void}
     */
    const adjustTooltipPosition = () => {
      const bubble = bubbleRef.current;
      if (!bubble) {
        return;
      }

      const bubbleRect = bubble.getBoundingClientRect();
      const tooltip = bubble.querySelector(".reference-tooltip");

      tooltip.style.left = `${bubbleRect.width - 2}px`;
    };

    // Call function initially to position tooltip
    adjustTooltipPosition();

    // Call function on window resize
    window.addEventListener("resize", adjustTooltipPosition);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", adjustTooltipPosition);
  }, []);

  // decodeURIComponent is needed because the href is encoded when it is passed to the renderer
  const referenceString = decodeURIComponent(href);

  // Find the reference data for this id

  const referenceData = chunkInfo.find(
    (r) => r.referenceString === referenceString
  );

  // If there's no reference data, just render the link as usual
  if (!referenceData) {
    return <a href={href} target="_blank" rel="noreferrer">{children}</a>;
  }

  // If there is reference data, render it. but change the href to point to the articleId
  const articleReferenceNumber =
    chunkToArticleReferenceNumberMap[referenceString];

  return (
    <React.Fragment>
      {/* render an invisible tag in a shape of [1]{1} with article number; this is used to re-link the article
      when we create an insight from the assitant */}
      <span
        style={{ color: "invisible", fontSize: "0px" }}
      >{`[${articleReferenceNumber}]{${articleReferenceNumber}}`}</span>
      <span
        className="reference-bubble-org"
        data-tooltip={referenceData.content}
        ref={bubbleRef}
      >
        {[`${articleReferenceNumber}`]}
        <span
          className="reference-tooltip"
          style={{
            position: "absolute",
            visibility: SHOW_CHUNK_CONTENT ? "visible" : "hidden",
          }}
        >
          {referenceData.content}
        </span>
      </span>
    </React.Fragment>
  );
};

export default ReferenceBubble;
