import { AbsoluteCenter, Box, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import { RiSparklingLine } from "react-icons/ri";
import { ProjectWithPermission } from "src/Context/ProjectContext";
import { getCustomizationData } from "../Utils/customizationUtils";
import { ProjectListCard } from "./ProjectListCard";

const customization = getCustomizationData();
/**
 * Renders a list of pinned and unpinned projects.
 *
 * @param {Object} props
 * @param {Array} props.projects - The array of project data.
 * @param {number} [props.visibleRows] - The number of visible rows.
 * @param {Array} props.currentlyPinnedProjects - The array of currently pinned project IDs.
 * @param {number} props.CARD_GAP - The gap between project cards.
 * @param {number} props.CARD_WIDTH - The width of each project card.
 * @param {string} props.rowTitle - The title of the row.
 * @param {React.ReactElement} [props.icon] - The icon for the row.
 * @param {boolean} [props.showTitle] - Whether to show the title.
 * @param {boolean} [props.showNoProjectsText] - Whether to show the no projects text.
 */
export const ProjectsList = ({
  projects,
  visibleRows,
  currentlyPinnedProjects,
  CARD_GAP,
  CARD_WIDTH,
  rowTitle,
  icon,
  showTitle = true,
  showNoProjectsText = false,
}: {
  projects: ProjectWithPermission[];
  visibleRows?: number;
  currentlyPinnedProjects: string[];
  CARD_GAP: number;
  CARD_WIDTH: number;
  rowTitle: string;
  icon?: React.ReactElement;
  showTitle?: boolean;
  showNoProjectsText?: boolean;
}) => {
  return (
    <Flex flexDir="column">
      {showTitle ? (
        <Box position="relative" my={4}>
          <Divider />
          <AbsoluteCenter px={4} bg="#f8f8f8" fontSize="13px" color="gray.400">
            {icon ? <Box as="span">{icon}</Box> : null}
            {rowTitle}
          </AbsoluteCenter>
        </Box>
      ) : null}
      {showNoProjectsText ? (
        <Flex w="100%" align="center" py="24" flexDir="column" gap="4px">
          <RiSparklingLine fontSize="40px" />
          {customization.homePage.noProjectText}
        </Flex>
      ) : null}
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap={`${CARD_GAP}px`}
        gridTemplateColumns={`repeat(auto-fill, minmax(${CARD_WIDTH}px, 1fr))`}
        maxHeight={
          visibleRows ? `${visibleRows * (CARD_WIDTH + CARD_GAP)}px` : "auto"
        }
        overflow="hidden"
        mt="4"
      >
        {projects?.map((projectWithPermission, index) => (
          <ProjectListCard
            isFirstItem={index === 0}
            key={index}
            projectWithPermission={projectWithPermission}
            isPinned={currentlyPinnedProjects?.includes(
              projectWithPermission.data.uid
            )}
          />
        ))}
      </Box>
    </Flex>
  );
};
