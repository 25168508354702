import { Button } from "@chakra-ui/react";
import React from "react";
import { ArticleDoc, ArticleType } from "shared/articleTypes";
import { useArticle } from "../../../Context/ArticleContext";
import { ARTICLE_TYPE_DATA } from "../../../data/articleTypeData";
import { deleteArticle } from "../../../db/article";
import { updateArticleTypeParams } from "../../ProjectPage/TableComponents/HeaderSwitches";

const order = Object.entries(ARTICLE_TYPE_DATA).reduce((map, [type, data]) => {
  map[type] = data.sortOrder;
  return map;
}, {} as Record<ArticleType, number>);

export const sortTableArticles = (
  articles: ArticleDoc[],
  sortBy: string
): ArticleDoc[] => {
  const sortedData = [...articles];

  switch (sortBy) {
    case "name":
      sortedData.sort((a, b) => a.title.localeCompare(b.title));
      break;
    case "name-desc":
      sortedData.sort((a, b) => b.title.localeCompare(a.title));
      break;
    case "type":
      sortedData.sort((a, b) => order[a.type] - order[b.type]);
      break;
    case "type-desc":
      sortedData.sort((a, b) => order[b.type] - order[a.type]);
      break;
    case "source":
      sortedData.sort((a, b) => a.source.localeCompare(b.source));
      break;
    case "source-desc":
      sortedData.sort((a, b) => b.source.localeCompare(a.source));
      break;
    case "date added":
      sortedData.sort((a, b) => a.createdAt - b.createdAt);
      break;
    case "date added-desc":
      sortedData.sort((a, b) => b.createdAt - a.createdAt);
      break;
    case "length":
      sortedData.sort((a, b) => (a.length || 0) - (b.length || 0));
      break;
    case "length-desc":
      sortedData.sort((a, b) => (b.length || 0) - (a.length || 0));
      break;
    case "added by":
      sortedData.sort((a, b) => a.createdBy?.localeCompare(b.createdBy || ""));
      break;
    case "added by-desc":
      sortedData.sort((a, b) => b.createdBy?.localeCompare(a.createdBy || ""));
      break;
    default:
      return articles;
  }

  sortedData.sort((a, b) => {
    if (a.starred && !b.starred) {
      return -1;
    } else if (!a.starred && b.starred) {
      return 1;
    } else {
      return 0;
    }
  });
  console.log("sorted =====>", sortedData);
  return sortedData;
};

export const handleDeleteMultipleArticle = async (projectId, articleIds) => {
  articleIds.forEach((articleId) => {
    deleteArticle(projectId, articleId);
  });
};

export const handleDownload = (dataList) => {
  console.log(dataList);
};

export const MoreActionMenuItem = ({ text, icon, id, action }) => {
  const { selectedArticlesData } = useArticle();

  const handleDownloadAllArticles = () => {
    handleDownload(selectedArticlesData);
  };
  const Icon = icon;
  return (
    <Button
      p="0"
      fontSize="12px"
      color="#707070"
      w="100%"
      justifyContent="start"
      leftIcon={<Icon fontSize="16px" />}
      fontWeight="600"
      px="4"
      py="1"
      h="fit-content"
      pr={4}
      _hover={{
        bg: "#f7f7f7",
      }}
      onClick={() => {
        if (id === "download") {
          handleDownloadAllArticles();
        } else if (id === "delete") {
          action();
        }
      }}
    >
      {text}
    </Button>
  );
};
// export const getArticleTypeText = (type) => {
//   switch (type) {
//     case "html":
//       return "Web Pages";
//     case "pdf":
//       return "PDFs";
//     case "docx":
//       return "Docs";
//     case "table":
//       return "Tables";
//     case "supportingLink":
//       return "Supporting Links";
//     case "xlsx":
//       return "Excel";
//     case "pptx":
//       return "Slides";
//     default:
//       return "Unknown";
//   }
// };

export const updateSelectedTypedArr = (
  type,
  selectedArticleTypes,
  routerHistory
) => {
  if (selectedArticleTypes.includes(type)) {
    updateArticleTypeParams(type, false, routerHistory);
  } else {
    updateArticleTypeParams(type, true, routerHistory);
  }
};

export type ArticleTypeCount = {
  count: number;
  id: ArticleType;
  type: string;
};

export const getArticleTypes = (
  allUnfilteredArticles: ArticleDoc[]
): Record<ArticleType, ArticleTypeCount> => {
  return allUnfilteredArticles.reduce((articleTypes, article) => {
    const { type } = article;

    if (!articleTypes[type]) {
      articleTypes[type] = {
        count: 0,
        id: type,
        type: ARTICLE_TYPE_DATA[type].uiNamePlural,
      };
    }

    articleTypes[type].count += 1;
    return articleTypes;
  }, {} as Record<ArticleType, ArticleTypeCount>);
};

export const calculateAndSetVisibleItems = ({
  menuContainerRef,
  sortedArticleType,
  setVisibleItems,
  setHiddenItems,
}) => {
  const containerWidth = menuContainerRef?.current.offsetWidth;

  let totalWidth = 0;
  const newVisibleItems = [] as ArticleType[];

  sortedArticleType?.forEach((type) => {
    const estimatedWidth = 95;
    if (totalWidth + estimatedWidth * 2 <= containerWidth) {
      newVisibleItems.push(type);
      totalWidth += estimatedWidth;
    }
  });
  if (newVisibleItems.length < sortedArticleType.length) {
    const allHiddenItems =
      newVisibleItems.length > 0
        ? sortedArticleType.slice(newVisibleItems.length)
        : sortedArticleType;
    setHiddenItems(allHiddenItems);
  }
  setVisibleItems(newVisibleItems);
};
