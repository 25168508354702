import { Button, Flex, FormControl, Input, Text } from "@chakra-ui/react";
import md5 from "md5";
import React, { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ArticleType, IndexingStatus } from "shared/articleTypes";
import { useAuth } from "../../Auth/AuthProvider";
import { createArticle } from "../../db/article";
import { SmartImageUploadComponent } from "./SmartImageUploadComponent.1";

export const SmartImageUpload = ({ onClose }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [smartImageTitle, setSmartImageTitle] = useState("");
  const { currentUser } = useAuth();
  const { projectId } = useParams();

  const handleImageUpload = (url) => {
    setImageUrl(url);
  };

  const handleArticleSubmission = async () => {
    const payload = {
      createdAt: Date.now(),
      createdBy: currentUser.uid,
      type: "smartImage" as ArticleType,
      source: "local",
      title: smartImageTitle,
      imageUrl: imageUrl,
      indexingStatus: {
        status: "pending" as IndexingStatus,
      },
    };

    await createArticle({ projectId, articleId: md5(imageUrl), payload });
    setSmartImageTitle("");
    setImageUrl(null);
    onClose();
  };
  return (
    <Flex pt="24px">
      <FormControl>
        <Flex flexDir="column" gap="16px">
          <Input
            type="text"
            placeholder="Add image title"
            onChange={(e) => setSmartImageTitle(e.target.value)}
            value={smartImageTitle}
          />
          <SmartImageUploadComponent
            onUpload={handleImageUpload}
            projectId={projectId}
          />
        </Flex>
        <Button
          onClick={handleArticleSubmission}
          isDisabled={!imageUrl || !smartImageTitle}
          bg="#282C3E"
          rounded="full"
          w="full"
          mt={4}
        >
          <Text fontSize="sm">Create</Text>
        </Button>
      </FormControl>
    </Flex>
  );
};
