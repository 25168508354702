import { useQuery } from "@tanstack/react-query";
import { doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { AiModuleDoc, AiModulePermission } from "shared/aiModule";
import { getCurrentUser } from "src/Auth/AuthProvider";
import {
    aiModuleDocCollection,
    aiModulePermissionsCollectionGroup,
    getModulePresentationDoc,
    Presentation,
} from "src/db/aiModule";
import { queryKeyAiModules } from "src/db/queryKeys";


export interface EnrichedPresentation extends Presentation {
    uid: string;
    moduleDocData?: AiModuleDoc;
    accentColor?: string;
    isPublic?: boolean;
    permission?: AiModulePermission;
    inUserLibrary?: boolean;
  }
  
  export async function getModulePresentationData(moduleDoc) {
    const module = moduleDoc.data();
    const activeVersionId = module.activeVersionId;
    const presentationRef = getModulePresentationDoc(
      moduleDoc.id,
      activeVersionId
    );
    const presentationDoc = await getDoc(presentationRef);
    const modulePresentationData = presentationDoc.data();
    return modulePresentationData;
  }
  

const useAiModules = () => {
  const {
    data: modules,
    isLoading,
    error,
  } = useQuery<{
    ownedModules: EnrichedPresentation[];
    privateModules: EnrichedPresentation[];
    userPublicModules: EnrichedPresentation[];
    libraryModules: EnrichedPresentation[];
    topRatedModules: EnrichedPresentation[];
    libraryUniqueCategories: string[];
  }>({
    queryKey: [queryKeyAiModules],
    queryFn: async () => {
      console.log("Fetching AI Modules");

      const publicModules = await fetchPublicAiModules();
      const userModules = await fetchUserAiModules();

      const ownedModules = userModules
        .filter((module) => module.permission?.role === "owner")
        .map((module) => ({
          ...module,
          accentColor: "accentHighlights",
        }));

      const userPublicModules = userModules
        .filter((module) => module.moduleDocData.inLibrary)
        .map((module) => ({
          ...module,
          isPublic: true,
          accentColor: "accentSnippets",
        }));

      const libraryModules = publicModules.map((publicModule) => {
        const isAddedByUser = userPublicModules.some(
          (userModule) => userModule.uid === publicModule.uid
        );
        return {
          ...publicModule,
          inUserLibrary: isAddedByUser,
          accentColor: "accentSnippets",
        };
      });

      const privateModules = userModules
        .filter((module) => {
          if (
            libraryModules.some(
              (libraryModule) => libraryModule.uid === module.uid
            )
          ) {
            return false;
          }
          return module?.permission?.role === "user";
        })
        .map((module) => ({
          ...module,
          accentColor: "accentHighlights",
        }));

      const libraryUniqueCategories = [
        ...new Set(
          libraryModules.map((module) => module.moduleDocData?.category)
        ),
      ].filter((category): category is string => category !== undefined);

      const topRatedModules = libraryModules.filter(
        (module) => module.moduleDocData?.isTopRated
      );

      return {
        ownedModules,
        privateModules,
        userPublicModules,
        libraryModules,
        libraryUniqueCategories,
        topRatedModules,
      };
    },
    // staleTime: Infinity,
  });

  return { modules, isLoading, error };
};

async function fetchPublicAiModules() {
  const publicModulesRef = query(
    aiModuleDocCollection,
    where("inLibrary", "==", true)
  );
  const publicModulesDocs = await getDocs(publicModulesRef);
  const publicModulesPromises = publicModulesDocs.docs.map(
    async (moduleDoc) => {
      const modulePresentationData = await getModulePresentationData(moduleDoc);
      if (!modulePresentationData) {
        return null;
      }
      return {
        ...modulePresentationData,
        uid: moduleDoc.id,
        accentColor: "accentSnippets",
        moduleDocData: moduleDoc.data(),
      };
    }
  );
  const publicModules = (await Promise.all(publicModulesPromises)).filter(
    // NonNullable<typeof module> is here to fix a runtime typecheck error
    (module): module is NonNullable<typeof module> => module !== null
  );

  // Sort alphabetically by title
  publicModules.sort((a, b) => (a && b ? a.title.localeCompare(b.title) : 0));
  return publicModules;
}

async function fetchUserAiModules() {
  const currentUser = getCurrentUser();
  const aiModulePermissions = query(
    aiModulePermissionsCollectionGroup,
    where("userId", "==", currentUser.uid)
  );
  const userModulesPermissions = await getDocs(aiModulePermissions);
  const userModulesPromises = userModulesPermissions.docs.map(
    async (permissionDoc) => {
      // console.log("userModulePromises doc :>> ", doc);
      // retrive the actual module data
      const moduleIdRef = permissionDoc.ref.parent.parent;
      if (!moduleIdRef) {
        return null;
      }
      // retrieve the module data
      // const moduleDoc = await getDoc(moduleIdRef);
      const moduleDoc = await getDoc(
        doc(aiModuleDocCollection, moduleIdRef.id)
      );
      if (!moduleDoc.exists()) {
        return null;
      }
      const modulePresentationData = await getModulePresentationData(moduleDoc);
      if (!modulePresentationData) {
        return null;
      }

      return {
        ...modulePresentationData,
        uid: moduleDoc.id,
        permission: permissionDoc.data(),
        moduleDocData: moduleDoc.data(),
      };
    }
  );
  // filter out null promises, which may exist when permission doc exists, but module doc does not
  // (not yet created, or deleted)
  const userModules = (await Promise.all(userModulesPromises)).filter(
    // NonNullable<typeof module> is here to fix a runtime typecheck error
    (module): module is NonNullable<typeof module> => module !== null
  );
  return userModules;
}

export default useAiModules;
