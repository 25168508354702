import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { EnrichedPresentation } from "src/hooks/useAiModules";
import { Action, AiModuleBox, CustomPromptListGrid } from "./AiModuleBox";

export const AiModulesSection = ({
  isAiModulesAdmin,
  userCustomAiModules,
  title,
  Icon,
  onClick,
  action,
  getAction,
}: {
  isAiModulesAdmin: boolean;
  userCustomAiModules: EnrichedPresentation[];
  title: string;
  Icon: React.FC;
  action?: Action;
  onClick?: (module: EnrichedPresentation) => void;
  getAction?: (props: {
    module: EnrichedPresentation;
    isAiModulesAdmin: boolean;
  }) => Action;
}) => {
  if (userCustomAiModules.length === 0) return null;
  return (
    <Flex flexDir="column">
      <Flex flexDir="column">
        <Flex align="center" gap="6px">
          <Icon />
          <Text color="primary" fontSize="10px">
            {title}
          </Text>
        </Flex>
        <Box py="10px">
          <Divider orientation="horizontal" flex="1" />
        </Box>
      </Flex>
      <CustomPromptListGrid>
        {userCustomAiModules.map((module, index) => {
          return (
            <AiModuleBox
              key={index}
              moduleData={module}
              isLoading={false}
              action={
                action ||
                (getAction ? getAction({ module, isAiModulesAdmin }) : "NONE")
              }
              actionAlwaysOn={true}
              actionOnlyOnIcon={true}
              onClick={onClick ? () => onClick(module) : undefined}
            />
          );
        })}
      </CustomPromptListGrid>
    </Flex>
  );
};
