import {
  Button,
  Divider,
  Flex,
  HStack,
  Link,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  MdOutlineArrowBack,
  MdOutlinePublish,
  MdOutlineSpellcheck,
} from "react-icons/md";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { useAuth, UserWithEmail } from "../../Auth/AuthProvider";

import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { useQuery } from "@tanstack/react-query";
import { doc, getDoc } from "firebase/firestore";
import yaml from "js-yaml";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { aiModulesCollection, getModuleDefinitionDoc } from "src/db/aiModule";
import useNotification from "src/hooks/useNotification";
import Layout from "../Layout";
import AdminControls from "./AdminControls";
import { MultistepModuleDefinition } from "./multistepAiModuleTypes";
import {
  releaseNewMultistepModuleVersion,
  useCreateNewMultistepModule,
} from "./multistepAiModuleUtils";
import useValidateMultistepConfig, {
  ValidationResponse,
} from "./useValidateMultistepConfig";
import YamlEditor from "./YamlEditor";

/**
 * Sorts the keys of an object (and its nested objects, i.e. recursively) in a specific order.
 * @param obj - The object to sort
 * @param firstKeys - Array of keys that should appear first
 * @param lastKeys - Array of keys that should appear last
 * @returns The sorted object
 */
const sortKeys = (
  obj: object,
  firstKeys: string[] = [],
  lastKeys: string[] = []
): object => {
  const defaultFirstKeys = [
    "name",
    "type",
    "description",
    "api_version",
    "user_message",
    "system_message",
    "model",
    "temperature",
    "requirements",
    "client_input",
  ];

  const defaultLastKeys = ["steps", "inputs", "outputs"];

  const order = [...firstKeys, ...defaultFirstKeys];
  const finalLastKeys = [...lastKeys, ...defaultLastKeys];

  if (Array.isArray(obj)) {
    return obj.map((item) => sortKeys(item, firstKeys, finalLastKeys));
  } else if (typeof obj === "object" && obj !== null) {
    const sorted = {};

    // First, add keys in the specified order
    order.forEach((key) => {
      if (key in obj && !finalLastKeys.includes(key)) {
        sorted[key] = sortKeys(obj[key], firstKeys, finalLastKeys);
      }
    });

    // Then add any remaining keys that aren't in lastKeys
    Object.keys(obj).forEach((key) => {
      if (!order.includes(key) && !finalLastKeys.includes(key)) {
        sorted[key] = sortKeys(obj[key], firstKeys, finalLastKeys);
      }
    });

    // Finally add the lastKeys
    finalLastKeys.forEach((key) => {
      if (key in obj) {
        sorted[key] = sortKeys(obj[key], firstKeys, finalLastKeys);
      }
    });

    return sorted;
  }
  return obj;
};

const validateModuleDefinition = (data: MultistepModuleDefinition) => {
  const missingFields: string[] = [];
  if (!data.config.name) missingFields.push("name");
  if (!data.config.description) missingFields.push("description");
  if (!data.config.steps) missingFields.push("steps");
  return missingFields;
};

const handleReleaseNewModuleVersion = async (
  moduleId: string,
  moduleDefinition: MultistepModuleDefinition,
  toast,
  history
) => {
  const missingFields = validateModuleDefinition(moduleDefinition);

  if (missingFields.length > 0) {
    // alert(`Please fill in the following fields: ${missingFields.join(", ")}`);
    toast({
      title: "Please fill in the following fields",
      description: missingFields.join(", "),
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
    return;
  }
  await releaseNewMultistepModuleVersion(moduleId, moduleDefinition);

  toast({
    title: "Module version released",
    description: "Your module version has been released successfully",
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top-right",
  });

  history.push("/user?tab=custom-ai");
};

const useGetModuleDefinition = (
  moduleId: string | undefined,
  versionId: string | undefined
) => {
  const {
    data: moduleDefinition,
    isLoading,
    error,
  } = useQuery<MultistepModuleDefinition>({
    queryKey: ["moduleDefinition", moduleId, versionId],
    queryFn: async () => {
      if (!moduleId || !versionId) {
        throw new Error("Module ID and Version ID are required");
      }
      const moduleYamlRef = getModuleDefinitionDoc(moduleId, versionId);
      const moduleYamlDoc = await getDoc(moduleYamlRef);
      const data = moduleYamlDoc.data();
      if (!data) {
        throw new Error(
          `Module definition not found for moduleId: ${moduleId} and versionId: ${versionId}`
        );
      }
      return data as MultistepModuleDefinition;
    },
    staleTime: Infinity,
  });

  return { moduleDefinition, isLoading, error };
};

function ValidationControls({
  moduleConfig,
  yamlError,
  validationStatus,
  setValidationResponse,
}: {
  moduleConfig: MultistepModuleDefinition["config"];
  yamlError: boolean;
  validationStatus: "unknown" | "valid" | "invalid";
  setValidationResponse: (r: ValidationResponse) => void;
}) {
  const validateMultistepConfig = useValidateMultistepConfig();

  const validationIcon = {
    unknown: <MdOutlineSpellcheck fontSize="16px" />,
    valid: <CheckCircleIcon color="green" />,
    invalid: <WarningIcon color="red" />,
  };

  return (
    <Button
      color="#727272"
      fontSize="12px"
      cursor="pointer"
      p="0"
      fontWeight="normal"
      onClick={async () => {
        const validated = await validateMultistepConfig.mutateAsync(
          moduleConfig
        );
        setValidationResponse(validated);
      }}
      isLoading={validateMultistepConfig.isPending}
      leftIcon={validationIcon[validationStatus]}
      isDisabled={yamlError}
    >
      Validate
    </Button>
  );
}

function CreateNewModuleControls({
  currentUser,
  moduleConfig,
  validationStatus,
}: {
  currentUser: UserWithEmail;
  moduleConfig: MultistepModuleDefinition["config"];
  validationStatus: "unknown" | "valid" | "invalid";
}) {
  const toast = useToast();
  const history = useHistory();

  const { mutateAsync: createNewMultistepModule } =
    useCreateNewMultistepModule();

  const handleCreateNewModule = async () => {
    const moduleDefinition: MultistepModuleDefinition = {
      createdBy: currentUser.uid,
      config: moduleConfig,
    };

    const missingFields = validateModuleDefinition(moduleDefinition);

    if (missingFields.length > 0) {
      toast({
        title: "Please fill in the following fields",
        description: missingFields.join(", "),
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    await createNewMultistepModule({
      moduleConfig: moduleDefinition,
      userId: currentUser.uid,
      userEmail: currentUser.email,
    });

    toast({
      title: "Module created",
      description: "Your module has been created successfully",
      status: "success",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });

    history.push("/user?tab=custom-ai");
  };

  return (
    <Tooltip
      label={validationStatus !== "valid" && "Validate configuration first"}
    >
      <Button
        color="#727272"
        fontSize="12px"
        cursor="pointer"
        p="0"
        fontWeight="normal"
        onMouseDown={handleCreateNewModule}
        isDisabled={validationStatus !== "valid"}
      >
        <MdOutlinePublish fontSize="16px" />
        <Text>Create New Module</Text>
      </Button>
    </Tooltip>
  );
}

function PublishNewVersionControls({
  moduleId,
  versionId,
  currentUser,
  moduleConfig,
  validationStatus,
}: {
  moduleId: string | undefined;
  versionId: string | undefined;
  currentUser: UserWithEmail;
  moduleConfig: MultistepModuleDefinition["config"];
  validationStatus: "unknown" | "valid" | "invalid";
}) {
  const toast = useToast();
  const history = useHistory();

  const handlePublishNewVersion = async () => {
    if (!(moduleId && versionId)) {
      toast({
        title: "Error",
        description:
          "Module ID and Version ID are required to publish a new version",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const moduleDefinition: MultistepModuleDefinition = {
      createdBy: currentUser.uid,
      config: moduleConfig,
    };

    await handleReleaseNewModuleVersion(
      moduleId,
      moduleDefinition,
      toast,
      history
    );
  };

  return (
    <Tooltip
      label={validationStatus !== "valid" && "Validate configuration first"}
    >
      <Button
        color="#727272"
        fontSize="12px"
        cursor="pointer"
        p="0"
        fontWeight="normal"
        onMouseDown={handlePublishNewVersion}
        isDisabled={validationStatus !== "valid"}
      >
        <MdOutlinePublish fontSize="16px" />
        <Text>Publish New Version</Text>
      </Button>
    </Tooltip>
  );
}

const useGetActiveModuleData = (moduleId: string | undefined) =>
  useQuery({
    queryKey: ["activeModuleVersion", moduleId],
    queryFn: async () => {
      if (!moduleId) {
        throw new Error("Module ID is required");
      }
      const moduleRef = doc(aiModulesCollection, moduleId);
      const moduleDocData = await getDoc(moduleRef);
      return moduleDocData.data();
    },
    staleTime: Infinity,
  });

function ModuleName({ name }: { name: string | undefined }) {
  return (
    <Flex h="14px" align={"center"}>
      <Text
        fontWeight="medium"
        fontSize="14px"
        border="none"
        pl="8px"
        w="fit-content"
      >
        {name || "New Module"}
      </Text>
    </Flex>
  );
}

function EditorHeader({
  moduleConfig,
  yamlError,
  validationResponse,
  setValidationResponse,
  versionId,
  moduleId,
  currentUser,
}: {
  moduleConfig: MultistepModuleDefinition["config"];
  yamlError: boolean;
  validationResponse: ValidationResponse | undefined;
  setValidationResponse: (r: ValidationResponse) => void;
  versionId: string | undefined;
  moduleId: string | undefined;
  currentUser: UserWithEmail;
}) {
  let validationStatus: "unknown" | "valid" | "invalid" = "unknown";
  if (validationResponse) {
    if (validationResponse.errors.length === 0) {
      validationStatus = "valid";
    } else {
      validationStatus = "invalid";
    }
  }

  return (
    <Flex gap={2} direction="row" align="center" justify="space-between">
      <HStack gap={0}>
        <Link as={ReactRouterLink} to="/user?tab=custom-ai">
          <MdOutlineArrowBack color="primary" fontSize="18px" />
        </Link>
        <ModuleName name={moduleConfig?.name} />
      </HStack>
      <Flex gap="16px">
        <ValidationControls
          moduleConfig={moduleConfig}
          yamlError={yamlError}
          validationStatus={validationStatus}
          setValidationResponse={setValidationResponse}
        />
        {versionId ? (
          <PublishNewVersionControls
            moduleId={moduleId}
            versionId={versionId}
            currentUser={currentUser}
            moduleConfig={moduleConfig}
            validationStatus={validationStatus}
          />
        ) : (
          <CreateNewModuleControls
            currentUser={currentUser}
            moduleConfig={moduleConfig}
            validationStatus={validationStatus}
          />
        )}
      </Flex>
    </Flex>
  );
}

export const MultistepAiModuleEditor = () => {
  // retrieve query params
  const {
    moduleId,
    versionId,
  }: { moduleId: string | undefined; versionId: string | undefined } =
    useParams();
  const { currentUser, isAiModulesAdmin } = useAuth();
  const [moduleConfig, setModuleConfig] = useState<
    MultistepModuleDefinition["config"]
  >({
    name: "",
    description: "",
    steps: [],
  });
  const [moduleConfigStr, setModuleConfigStr] = useState("");
  const [yamlError, setYamlError] = useState(false);
  const [validationResponse, setValidationResponse] = useState<
    ValidationResponse | undefined
  >(undefined);

  const history = useHistory();
  const { notify } = useNotification();

  const {
    data: activeModuleVersion,
    isLoading: isActiveModuleVersionLoading,
    // error: activeModuleVersionError,
  } = useGetActiveModuleData(moduleId);
  const { moduleDefinition } = useGetModuleDefinition(moduleId, versionId);

  /*
   * When the module definition is loaded, set the module config string
   */
  useEffect(() => {
    if (moduleDefinition) {
      setModuleConfig(moduleDefinition.config);
      const sortedConfig = sortKeys(moduleDefinition.config);
      setModuleConfigStr(yaml.dump(sortedConfig, { lineWidth: -1 }));
    }
  }, [moduleDefinition]);

  /*
   * Parse the YAML string into a an object
   */
  useEffect(() => {
    try {
      yaml.load(moduleConfigStr);
      setYamlError(false);
    } catch (e) {
      console.error("Error parsing YAML string: ", e);
      setYamlError(true);
      return;
    }

    if (yaml.load(moduleConfigStr)) {
      setModuleConfig(
        yaml.load(moduleConfigStr) as MultistepModuleDefinition["config"]
      );
    } else {
      setModuleConfig({
        name: "",
        description: "",
        steps: [],
      });
    }
  }, [moduleConfigStr]);

  /** Reset validation whenever the module config changes */
  useEffect(() => {
    setValidationResponse(undefined);
  }, [moduleConfigStr]);

  // if moduleId is provided, but the active module version is not found,
  // redirect to module editor (without moduleId)
  if (
    moduleId &&
    !isActiveModuleVersionLoading &&
    activeModuleVersion === undefined
  ) {
    notify({
      title: `Module ${moduleId} not found`,
      status: "error",
    });
    history.push("/multistep-ai-module-editor");
    return null;
  }

  if (moduleId && !versionId && activeModuleVersion?.activeVersionId) {
    console.log(
      `Redirecting to module ${moduleId} version ${activeModuleVersion.activeVersionId}`
    );
    history.push(
      `/multistep-ai-module-editor/${moduleId}/${activeModuleVersion.activeVersionId}`
    );
    return null;
  }

  console.log("moduleId :>> ", moduleId);
  console.log("versionId :>> ", versionId);
  console.log("moduleConfigStr :>> ", moduleConfigStr);
  console.log("moduleConfig :>> ", moduleConfig);

  return (
    <Layout hideAssistant={true}>
      <Flex py="10px" />
      <Flex w="100%" gap="40px">
        <Flex flexDir="column" flex="2">
          <EditorHeader
            moduleId={moduleId}
            versionId={versionId}
            currentUser={currentUser}
            moduleConfig={moduleConfig}
            yamlError={yamlError}
            validationResponse={validationResponse}
            setValidationResponse={setValidationResponse}
          />
          {/* </Flex> */}
          <Flex direction="column">
            <Flex gap={6} flexDir="column">
              <Flex
                position="relative"
                h="70vh"
                border={"3px solid"}
                borderColor={yamlError ? "red" : "gray.200"}
                borderRadius={"10px"}
              >
                <YamlEditor
                  yamlCode={moduleConfigStr}
                  setYamlCode={setModuleConfigStr}
                />
              </Flex>
              {
                <Text
                  color="red.500"
                  fontSize="12px"
                  visibility={yamlError ? "visible" : "hidden"}
                >
                  Error parsing YAML string
                </Text>
              }
            </Flex>
          </Flex>
          <Divider borderColor="black" />
          {isAiModulesAdmin && <AdminControls />}
        </Flex>
        <Flex flex="1">
          {validationResponse && (
            <ReactMarkdown>{validationResponse.errors[0]}</ReactMarkdown>
          )}
        </Flex>
      </Flex>
    </Layout>
  );
};
