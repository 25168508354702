import {
  Box,
  Divider,
  Flex,
  HStack,
  Link,
  Switch,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdOutlineArrowBack, MdOutlinePublish } from "react-icons/md";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import {
  Instruction,
  Presentation,
  aiModulesCollection,
  getModuleInstructionDoc,
  getModulePresentationDoc,
} from "src/db/aiModule";
import Layout from "../Layout";
import AdminControls from "./AdminControls";
import { createNewModule, releaseNewModuleVersion } from "./AiModuleTypes";
import {
  InputCombinationWithMenu,
  InputCombinationWithTooltip,
} from "./InputCombinationWithTooltip";

interface AiModuleFormData {
  title: string;
  description: string;
  systemMessage: string;
  userMessage: string;
  temperature: number;
  variable?: string;
  variableDescription?: string;
  variableIsOptional?: boolean;
}

const validateModuleData = (data: AiModuleFormData) => {
  const missingFields: string[] = [];

  if (!data.title) missingFields.push("Title");
  if (!data.description) missingFields.push("Description");
  if (!data.systemMessage) missingFields.push("System message");
  if (!data.userMessage) missingFields.push("User message");

  const variableFieldsPresent = data.variable || data.variableDescription;

  if (variableFieldsPresent) {
    if (!data.variable) missingFields.push("Variable");
    if (!data.variableDescription) missingFields.push("Variable description");
    if (data.variableIsOptional === undefined)
      missingFields.push("Variable is optional");
  }

  return missingFields;
};

const handleCreateCustomAiModule = async (
  moduleData: AiModuleFormData,
  userId: string,
  userEmail: string,
  toast,
  history
) => {
  console.log("moduleData: ", moduleData);
  const missingFields = validateModuleData(moduleData);

  if (missingFields.length > 0) {
    // alert(`Please fill in the following fields: ${missingFields.join(", ")}`);
    toast({
      title: "Please fill in the following fields",
      description: missingFields.join(", "),
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
    return;
  }
  await createNewModule({
    title: moduleData.title,
    description: moduleData.description,
    systemMessage: moduleData.systemMessage,
    userMessage: moduleData.userMessage,
    temperature: moduleData.temperature,
    variable: moduleData.variable,
    variableDescription: moduleData.variableDescription,
    variableIsOptional: moduleData.variable
      ? moduleData.variableIsOptional
      : undefined,
    userId,
    userEmail,
  });

  toast({
    title: "Module created",
    description: "Your module has been created successfully",
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top-right",
  });

  history.push("/user?tab=custom-ai");
};

const handleReleaseNewModuleVersion = async (
  moduleId,
  moduleData: AiModuleFormData,
  toast,
  history,
  userId: string
) => {
  console.log("moduleData: ", moduleData);
  const missingFields = validateModuleData(moduleData);

  if (missingFields.length > 0) {
    // alert(`Please fill in the following fields: ${missingFields.join(", ")}`);
    toast({
      title: "Please fill in the following fields",
      description: missingFields.join(", "),
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
    return;
  }
  await releaseNewModuleVersion({
    moduleId,
    title: moduleData.title,
    description: moduleData.description,
    systemMessage: moduleData.systemMessage,
    userMessage: moduleData.userMessage,
    temperature: moduleData.temperature,
    variable: moduleData.variable,
    variableDescription: moduleData.variableDescription,
    variableIsOptional: moduleData.variable
      ? moduleData.variableIsOptional
      : undefined,
    userId,
  });

  toast({
    title: "Module version released",
    description: "Your module version has been released successfully",
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top-right",
  });

  history.push("/user?tab=custom-ai");
};

export const AiModuleEditor = () => {
  // retrieve query params
  const { moduleId, versionId } = useParams<{ moduleId: string }>();

  const [moduleData, setModuleData] = useState<AiModuleFormData>({
    title: "",
    description: "",
    systemMessage: "",
    userMessage: "",
    temperature: 0,
  });
  const [showVariable, setShowVariable] = useState(false);
  const { currentUser, isAiModulesAdmin } = useAuth();
  const toast = useToast();
  const history = useHistory();

  /**
   * Fetch the module data from the database
   */
  useEffect(() => {
    if (!moduleId && !versionId) return;

    // retrieve module data from the database
    const redirectToActiveVersion = async () => {
      const moduleRef = doc(aiModulesCollection, moduleId);
      const moduleDocData = await getDoc(moduleRef);
      if (!moduleDocData.exists()) {
        // redirect to the home page
        history.push("/");
      }

      const moduleData = moduleDocData.data();
      history.push(
        `/ai-module-editor/${moduleId}/${moduleData?.activeVersionId}`
      );
    };

    if (moduleId && !versionId) {
      redirectToActiveVersion();
    }

    if (moduleId && versionId) {
      const loadModuleData = async () => {
        // load data for the specific version directly
        const instructionRef = getModuleInstructionDoc(moduleId, versionId);
        const instructionDoc = await getDoc(instructionRef);
        const instructionData = instructionDoc.data() as Instruction;
        const presentationRef = getModulePresentationDoc(moduleId, versionId);
        const presentationDoc = await getDoc(presentationRef);
        const presentationData = presentationDoc.data() as Presentation;

        const variable =
          Object.keys(presentationData?.variables || {})[0] || undefined;

        if (!variable) {
          setShowVariable(false);
        } else {
          setShowVariable(true);
        }

        setModuleData({
          title: presentationData?.title || "",
          description: presentationData?.description || "",
          systemMessage: instructionData?.systemMessage || "",
          userMessage: presentationData?.userMessage || "",
          temperature: instructionData?.temperature || 0,
          variable: variable,
          variableDescription: variable
            ? presentationData?.variables?.[variable]?.description
            : undefined,
          variableIsOptional: variable
            ? presentationData?.variables?.[variable]?.optional
            : undefined,
        });
      };

      loadModuleData();
    }
  }, [moduleId, versionId]);

  console.log("moduleId :>> ", moduleId);
  console.log("versionId :>> ", versionId);
  console.log("moduleData :>> ", moduleData);

  return (
    <Layout rest={undefined} hideAssistant={true}>
      <Flex flexDir="column" w="60%">
        <Box my="6">
          <Flex gap={2} direction="row" align="center" justify="space-between">
            <HStack gap={0}>
              <Link as={ReactRouterLink} to="/user?tab=custom-ai">
                <MdOutlineArrowBack color="primary" fontSize="18px" />
              </Link>
              <Flex h="14px" align={"center"}>
                <Text
                  fontWeight="medium"
                  fontSize="14px"
                  border="none"
                  pl="8px"
                  w="fit-content"
                >
                  {moduleData.title}
                </Text>
              </Flex>
            </HStack>
            <Flex gap="10px">
              {!moduleId && isAiModulesAdmin && (
                <Link href="/multistep-ai-module-editor">
                  <Text color="#727272" fontSize="12px">
                    Switch to multistep
                  </Text>
                </Link>
              )}
              <Flex
                color="#727272"
                fontSize="12px"
                align="center"
                cursor="pointer"
                onMouseDown={async () => {
                  // if showVariable is false, set all related fields to undefined
                  let newModuleData: AiModuleFormData = moduleData;
                  if (!showVariable) {
                    newModuleData = {
                      ...moduleData,
                      variable: undefined,
                      variableDescription: undefined,
                      variableIsOptional: undefined,
                    };
                    setModuleData(newModuleData);
                  }

                  if (!(moduleId && versionId)) {
                    await handleCreateCustomAiModule(
                      newModuleData,
                      currentUser.uid,
                      currentUser.email,
                      toast,
                      history
                    );
                  } else {
                    await handleReleaseNewModuleVersion(
                      moduleId,
                      newModuleData,
                      toast,
                      history,
                      currentUser.uid
                    );
                  }
                }}
              >
                <MdOutlinePublish fontSize="16px" />
                <Text>Publish</Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Flex gap={6} flexDir="column">
          <InputCombinationWithTooltip
            title="Title"
            tooltipText="Used only for display and doesn't affect how the AI module works. Choose something descriptive."
            placeholder="Financial analysis..."
            width="100%"
            inputType="input"
            onChange={(value) => {
              setModuleData({ ...moduleData, title: value.toString() });
            }}
            defaultValue={moduleData.title}
          />
          <InputCombinationWithTooltip
            title="Description"
            tooltipText="Like the title, this is only for display and doesn’t impact how the AI module works."
            placeholder="Performs analysis on financial documents..."
            width="100%"
            inputType="textarea"
            onChange={(value) => {
              setModuleData({ ...moduleData, description: value.toString() });
            }}
            defaultValue={moduleData.description}
          />
          <InputCombinationWithTooltip
            title="System message"
            tooltipText="The main instruction for the AI module on what it should do, why, and how. Sets the overall tone and context"
            placeholder="Act as a financial advisor ..."
            width="100%"
            inputType="textarea"
            height="300px"
            onChange={(value) => {
              setModuleData({ ...moduleData, systemMessage: value.toString() });
            }}
            defaultValue={moduleData.systemMessage}
          />
          <InputCombinationWithTooltip
            title="User message"
            tooltipText="Enter a specific question or instruction for the AI to respond to each time you use this AI module. In most cases, it's fine to leave this blank. Only fill it in if you enable user input."
            width="100%"
            inputType="textarea"
            onChange={(value) => {
              setModuleData({ ...moduleData, userMessage: value.toString() });
            }}
            defaultValue={moduleData.userMessage}
            placeholder="User message text"
            height="70px"
          />
          <InputCombinationWithTooltip
            title="Temperature"
            tooltipText="Controls the AI’s creativity. Lower values (like 0) make responses more focused and predictable, while higher values add variety. For most tasks, especially data-focused ones, 0 is recommended. If unsure, leave it at 0."
            width="100%"
            inputType="numberInput"
            onChange={(value) => {
              setModuleData({ ...moduleData, temperature: Number(value) });
            }}
            defaultValue={moduleData.temperature}
            inputMode="numeric"
            placeholder={"0.0"}
            min={0}
            max={2}
          />
          {/* <HStack>
            <InputCombinationWithTooltip
              title="Good examples"
              tooltipText="This is the Good examples tooltip"
              placeholder="Good examples text"
              width="100%"
              inputType="input"
              onChange={(value) => {
                setPlaygroundData({ ...playgroundData, goodExamples: value });
              }}
            />
            <InputCombinationWithTooltip
              title="Bad examples"
              tooltipText="This is the Bad examples tooltip"
              placeholder="Bad examples text"
              width="100%"
              inputType="input"
              onChange={(value) => {
                setPlaygroundData({ ...playgroundData, badExamples: value });
              }}
            />
          </HStack> */}
          {/* <InputCombinationWithTooltip
            title="Desired output format"
            tooltipText="Desired output format tooltip"
            placeholder="Desired output format text"
            width="100%"
            inputType="input"
            onChange={(value) => {
              setPlaygroundData({
                ...playgroundData,
                desiredOutputFormat: value,
              });
            }}
          /> */}
          {/* <ReadOrRagMode
            value={readAndRagMode}
            updateReadAndRagMode={(value) => {
              setReadAndRagMode(value);
            }}
          /> */}
          <Divider borderColor="black" />
          <Flex gap={"10px"} align={"center"}>
            <Text fontSize="10px" fontWeight="500">
              {"No user input"}
            </Text>
            <Switch
              isChecked={showVariable}
              onChange={() => {
                setShowVariable(!showVariable);
              }}
            />
            <Text fontSize="10px" fontWeight="500">
              {"User input"}
            </Text>
            <Tooltip
              label={
                "Toggle this to enable or disable user input. If enabled, the module will prompt the user to input some additional information when the AI module runs. This is useful when the AI module requires context not available in the document."
              }
              ml={2}
              hasArrow
            >
              <InfoOutlineIcon
                ml="1"
                cursor="pointer"
                color="secondaryGrey"
                fontSize="10px"
              />
            </Tooltip>
          </Flex>
          <Flex
            direction={"column"}
            gap={"10px"}
            display={showVariable ? "flex" : "none"}
          >
            <HStack>
              <InputCombinationWithTooltip
                title="Variable"
                placeholder="any_variable"
                width="100%"
                inputType="input"
                onChange={(value) => {
                  setModuleData({ ...moduleData, variable: value.toString() });
                }}
                tooltipText={`Variable is used in the User message, and can be set at module invokation by the user. For example, if you add {{name}} in the User message, and set "name" in this field, when invoking the module the user will be prompted to enter a value for "name".`}
                defaultValue={moduleData.variable || ""}
              />
              <InputCombinationWithMenu
                title="Variable is optional?"
                width="100%"
                onChange={(value) => {
                  setModuleData({
                    ...moduleData,
                    variableIsOptional: value === "True",
                  });
                }}
                tooltipText="User can skip this variable when invoking the module"
                defaultValue={
                  moduleData.variableIsOptional === true
                    ? "True"
                    : moduleData.variableIsOptional === false
                    ? "False"
                    : ""
                }
              />
            </HStack>
            <InputCombinationWithTooltip
              title="Variable description"
              tooltipText=""
              placeholder="Variable description"
              width="50%"
              inputType="input"
              onChange={(value) => {
                setModuleData({
                  ...moduleData,
                  variableDescription: value.toString(),
                });
              }}
              defaultValue={moduleData.variableDescription || ""}
            />
          </Flex>
          <Divider borderColor="black" />
          {isAiModulesAdmin && <AdminControls />}
          {/* <InputCombinationWithAddIcon
            title="User’s email adresses"
            tooltipText="User’s email adresses"
            placeholder="email@email.com"
            width="50%"
            inputType="input"
            onChange={(value: string) => {
              setEmails([...emails, value]);
            }}
          /> */}
        </Flex>
        {/* <List spacing={3} mt={2}>
          {emails.map((email, index) => (
            <ListItem key={index}>
              <HStack>
                <Text
                  display="inline-block"
                  fontSize="10px"
                  color="secondaryGrey"
                >
                  {email}
                </Text>
                <ListIcon
                  fontSize="13px"
                  as={IoMdCheckmark}
                  color="green.500"
                />
              </HStack>
            </ListItem>
          ))}
        </List> */}
      </Flex>
    </Layout>
  );
};
