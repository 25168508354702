import { useState } from "react";
import { updateSnippet } from "../db/snippet";
import { auth } from "../firebase";

const useInsight = (projectId) => {
  const [activeInsight, setActiveInsight] = useState(null);
  const updateSnippetCurated = (snippetId, isCurated) => {
    updateSnippet({
      projectId,
      snippetId,
      curated: isCurated,
      createdBy: auth.currentUser.uid,
    });
  };

  return {
    activeInsight,
    setActiveInsight,
    updateSnippetCurated,
  };
};

export default useInsight;
