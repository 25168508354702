import { Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { FiUser } from "react-icons/fi";
import { IoGrid } from "react-icons/io5";
import { MdOutlineLock } from "react-icons/md";
import { Action } from "src/Components/AiModules/AiModuleBox";
import useAiModules, { EnrichedPresentation } from "src/hooks/useAiModules";
import { useAuth } from "../../../Auth/AuthProvider";
import { AiModulesSection } from "../../AiModules/AiModuleSection";
import { getCustomizationData } from "../../Utils/customizationUtils";

const customization = getCustomizationData();

const getAction = ({ module }) => {
  if (module.isPublic) {
    return "DELETE";
  } else {
    if (["owner", "editor"].includes(module.permission.role)) {
      return "EDIT";
    } else {
      return "PRIVATE_INDICATOR";
    }
  }
};

export const AiModulesList = ({
  action,
  onClick,
}: {
  action?: Action;
  onClick?: (module: EnrichedPresentation) => void;
}) => {
  const { isAiModulesAdmin } = useAuth();
  const { modules } = useAiModules();

  if (!modules) return null;

  const { ownedModules, privateModules, userPublicModules } = modules;

  return (
    <Flex mt="1px" flexDir="column" gap="24px">
      <AiModulesSection
        isAiModulesAdmin={isAiModulesAdmin}
        getAction={getAction}
        userCustomAiModules={ownedModules}
        title={"My Custom Modules"}
        Icon={() => <Icon as={FiUser} fontSize="12px" />}
        onClick={onClick}
        action={action}
      />
      <AiModulesSection
        isAiModulesAdmin={isAiModulesAdmin}
        getAction={getAction}
        userCustomAiModules={privateModules}
        title={`Private modules - custom built by ${customization.name}`}
        Icon={() => <Icon as={MdOutlineLock} fontSize="12px" />}
        onClick={onClick}
        action={action}
      />
      <AiModulesSection
        isAiModulesAdmin={isAiModulesAdmin}
        getAction={getAction}
        userCustomAiModules={userPublicModules}
        title={"Public modules - added from library"}
        Icon={() => <Icon as={IoGrid} fontSize="12px" />}
        onClick={onClick}
        action={action}
      />
    </Flex>
  );
};
