import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useAiModules from "src/hooks/useAiModules";

const updateSearchParam = (selectedCategory, history) => {
  const currentSearchParams = new URLSearchParams(location.search);
  if (selectedCategory.includes("all")) {
    currentSearchParams.set("category", "all");
  } else {
    currentSearchParams.set("category", selectedCategory.join(","));
  }
  history.push({
    search: currentSearchParams.toString(),
  });
};

const handleFilterClick = (category, selectedCategory, history) => {
  let newSelectedCategory;

  if (category === "all") {
    newSelectedCategory = ["all"];
  } else {
    if (selectedCategory.length === 1 && selectedCategory.includes(category)) {
      newSelectedCategory = ["all"];
    } else {
      newSelectedCategory = selectedCategory.includes(category)
        ? selectedCategory.filter((selected) => selected !== category)
        : [...selectedCategory.filter((c) => c !== "all"), category];
    }
  }

  updateSearchParam(newSelectedCategory, history);
};

const FilterBttn = ({ text, id, isSelected, total, onClick }) => {
  return (
    <Button
      border="1px solid"
      borderColor={isSelected ? "#262626" : "#dddee0"}
      bg={isSelected ? "primary" : "transparent"}
      color={isSelected ? "white" : "#737580"}
      onClick={() => onClick(id)}
      borderRadius="100px"
      fontWeight="400"
      px="16px"
      py="7px"
      w="fit-content"
    >
      <Flex fontSize="12px" gap="1">
        <Text textTransform="capitalize">{text}</Text>
        <Text>{total}</Text>
      </Flex>
    </Button>
  );
};

export const LibraryPageCategoryFilter = () => {
  const history = useHistory();
  const location = useLocation();
  const currentSearchParams = new URLSearchParams(location.search);
  const selectedCategory =
    currentSearchParams.get("category")?.split(",") || [];

  const { modules } = useAiModules();
  if (!modules) return null;
  const { libraryUniqueCategories, libraryModules } = modules;

  if (libraryUniqueCategories.length === 0) {
    return null;
  }

  return (
    <Flex w="100%" pt="24px" flexDir="column">
      <Flex gap="8px">
        <FilterBttn
          text="All"
          id="all"
          isSelected={selectedCategory.includes("all")}
          total={libraryModules?.length}
          onClick={() => {
            handleFilterClick("all", selectedCategory, history);
          }}
        />
        {libraryUniqueCategories.map((category) => (
          <FilterBttn
            key={category}
            text={category}
            id={category}
            isSelected={selectedCategory.includes(category)}
            total={
              libraryModules.filter(
                (module) => module.moduleDocData?.category === category
              ).length
            }
            onClick={() => {
              handleFilterClick(category, selectedCategory, history);
            }}
          />
        ))}
      </Flex>
      <Divider borderColor="black" opacity="0.12" pt="16px" display="block" />
    </Flex>
  );
};
