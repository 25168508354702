import { Button, Flex, Heading, Img, Text } from "@chakra-ui/react";
import React from "react";
import { usePermitMobileStore } from "src/stores/permitMobile";
import DesktopOnly from "../../assets/desktopOnly.svg";
import { getCustomizationData } from "../Utils/customizationUtils";

const customization = getCustomizationData();

export const DesktopOnlyCompatibilityNotice = () => {
  const { setPermitMobile } = usePermitMobileStore();
  return (
    <Flex
      w="90%"
      m="0 auto"
      h="100vh"
      align="center"
      justifyContent="flex-end"
      flexDir="column"
    >
      <Flex
        flexDir="column"
        h="70%"
        align="center"
        justifyContent="space-between"
      >
        <Flex flexDir="column" align="center">
          <Img src={DesktopOnly} w="159px" />
          <Heading fontSize="24px" mt={6}>
            Welcome to {customization.name}!
          </Heading>
          <Text
            whiteSpace="normal"
            color="#202020"
            pt={4}
            pb={6}
            fontSize="16px"
            textAlign="center"
          >
            {customization.name} is optimized for desktop use, and it offers the
            best experience. However, if you want to access it on your mobile
            device, you can do so by clicking the button below.
          </Text>
          <Button
            bg="white"
            rounded="lg"
            borderColor="white"
            variant="outline"
            color="primary"
            id="addContent"
            fontSize="16px"
            size="lg"
            onClick={() => setPermitMobile(true)}
          >
            Access on mobile
          </Button>
        </Flex>

        <Img pb="4" w="88px" src={customization.logo} />
      </Flex>
    </Flex>
  );
};
