import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import {
  createCheckoutSession,
  getPrice,
} from "@invertase/firestore-stripe-payments";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { TOKENS_PER_PAGE } from "shared/constants";
import { payments } from "src/services/StripeSvc";
import {
  useActiveWorkspaceId,
  useActiveWorkspaceResources,
  useActiveWorkspaceSubscription,
} from "src/stores/userWorkspaces";
import { colors } from "src/theme/foundations/colors";
import {
  AI_CREDIT_PRICES,
  AI_STORAGE_PRICES,
  plansList,
} from "../UserPage/Plans/plansLists";
import { getCustomizationData } from "../Utils/customizationUtils";
import {
  AiCreditsResourceCard,
  AiPagesResourceCard,
  ResourceProgressBar,
  ResourceUsageText,
} from "./OfferCard";

const customization = getCustomizationData();

const useInitiateAiCreditsPayment = () => {
  const activeWorkspaceId = useActiveWorkspaceId();
  if (!activeWorkspaceId) {
    throw new Error("No active workspace id");
  }
  return useMutation({
    mutationFn: async (nCredits: number) => {
      const price = await getPrice(
        payments,
        process.env.REACT_APP_AI_CREDITS_PRODUCT_ID!,
        process.env[`REACT_APP_AI_CREDITS_${nCredits}_PRICE_ID`]!
      );
      const session = await createCheckoutSession(payments, {
        mode: "payment",
        price: price.id,
        success_url: `${window.location.origin}/user/profile/workspace?payment=success`,
        cancel_url: `${window.location.origin}/user/profile/workspace?payment=cancel`,
        metadata: {
          workspaceId: activeWorkspaceId,
        },
      });
      if (session) {
        window.location.assign(session.url);
      } else {
        throw new Error("Error creating checkout session.");
      }
    },
    onError: (error) => {
      console.error("AI Credits payment initiation failed:", error);
    },
  });
};

const useInitiateAiStoragePayment = () => {
  const activeWorkspaceId = useActiveWorkspaceId();
  if (!activeWorkspaceId) {
    throw new Error("No active workspace id");
  }
  return useMutation({
    mutationFn: async (nPagesThousands: number) => {
      const price = await getPrice(
        payments,
        process.env.REACT_APP_AI_STORAGE_PRODUCT_ID!,
        process.env[`REACT_APP_AI_STORAGE_${nPagesThousands}_PRICE_ID`]!
      );
      const session = await createCheckoutSession(payments, {
        mode: "payment",
        price: price.id,
        success_url: `${window.location.origin}/user/profile/workspace?payment=success`,
        cancel_url: `${window.location.origin}/user/profile/workspace?payment=cancel`,
        metadata: {
          workspaceId: activeWorkspaceId,
        },
      });
      if (session) {
        window.location.assign(session.url);
      } else {
        throw new Error("Error creating checkout session.");
      }
    },
    onError: (error) => {
      console.error("AI Storage payment initiation failed:", error);
    },
  });
};

export const WorkspaceAdditionalOffers = () => {
  const {
    aiCreditsPeriodic,
    aiCreditsExtra,
    aiTokenCountTotal,
    aiTokenCountRemaining,
    aiTokenCountUsed,
  } = useActiveWorkspaceResources();
  const { activePlan } = useActiveWorkspaceSubscription();
  const planMonthlyCredits = activePlan
    ? plansList[activePlan].monthlyAiCredits
    : 0;
  const [credits, setCredits] = useState<keyof typeof AI_CREDIT_PRICES>(100);
  const {
    mutateAsync: initiateCreditPurchase,
    isPending: isInitiatingCreditPurchase,
  } = useInitiateAiCreditsPayment();
  const [storage, setStorage] = useState<keyof typeof AI_STORAGE_PRICES>(15);
  const {
    mutateAsync: initiateStoragePurchase,
    isPending: isInitiatingStoragePurchase,
  } = useInitiateAiStoragePayment();

  let AiCreditsCard: React.ReactNode;
  if (activePlan === "free") {
    AiCreditsCard = (
      <AiCreditsResourceCard
        aiCreditsExtra={aiCreditsExtra}
        resourceUsageSection={
          <Text fontSize="12px" opacity="0.87" pb="36px">
            Sign up for a premium plan to receive AI credits every month, and
            have additional credits with pay-as-you-go pricing.
          </Text>
        }
      />
    );
  } else {
    AiCreditsCard = (
      <AiCreditsResourceCard
        aiCreditsExtra={aiCreditsExtra}
        resourceUsageSection={
          <ResourceUsageText
            percentage={Math.round(
              ((aiCreditsPeriodic ?? 0) / planMonthlyCredits) * 100
            )}
            usageText={`${
              aiCreditsPeriodic ?? 0
            }/${planMonthlyCredits} AI credits remaining`}
          />
        }
        resourceProgressBarSection={
          <ResourceProgressBar
            bandWidthUsed={Math.round(
              ((aiCreditsPeriodic ?? 0) / planMonthlyCredits) * 100
            )}
            indicator={colors.aiAccent[500]}
            colorScheme={"aiAccent"}
          />
        }
        purchaseExtraSection={
          <Flex pt="17px" w="100%" gap="8px">
            <Menu>
              <MenuButton
                color="black"
                border="1px solid"
                borderColor="#C2C2C2"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                fontSize="12px"
                h="35px"
                minW="178px"
                px="23px"
              >
                {credits} Credits - ${AI_CREDIT_PRICES[credits] / 100}
              </MenuButton>
              <MenuList>
                {Object.entries(AI_CREDIT_PRICES).map(([nCredits, price]) => (
                  <MenuItem
                    key={nCredits}
                    onClick={() => setCredits(Number(nCredits))}
                  >
                    {nCredits} Credits - ${price / 100}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Button
              maxW="71px"
              color="white"
              bg="primary"
              fontSize="12px"
              h="35px"
              onClick={async () => {
                await initiateCreditPurchase(credits);
              }}
              isLoading={isInitiatingCreditPurchase}
            >
              Buy
            </Button>
          </Flex>
        }
      />
    );
  }

  let AiStorageCard: React.ReactNode;
  if (activePlan === "free") {
    AiStorageCard = (
      <AiPagesResourceCard
        resourceUsageSection={
          <ResourceUsageText
            percentage={Math.round(
              ((aiTokenCountRemaining ?? 0) / aiTokenCountTotal) * 100
            )}
            usageText={`${(
              Math.round(aiTokenCountRemaining / TOKENS_PER_PAGE) ?? 0
            ).toLocaleString()}/${Math.round(
              aiTokenCountTotal / TOKENS_PER_PAGE
            ).toLocaleString()} pages remaining`}
          />
        }
        resourceProgressBarSection={
          <ResourceProgressBar
            bandWidthUsed={Math.round(
              ((aiTokenCountUsed ?? 0) / aiTokenCountTotal) * 100
            )}
            indicator={colors.aiStorage[500]}
            colorScheme={"aiStorage"}
          />
        }
      />
    );
  } else {
    AiStorageCard = (
      <AiPagesResourceCard
        resourceUsageSection={
          <ResourceUsageText
            percentage={Math.round(
              ((aiTokenCountRemaining ?? 0) / aiTokenCountTotal) * 100
            )}
            usageText={`${(
              Math.round(aiTokenCountRemaining / TOKENS_PER_PAGE) ?? 0
            ).toLocaleString()}/${Math.round(
              aiTokenCountTotal / TOKENS_PER_PAGE
            ).toLocaleString()} pages remaining`}
          />
        }
        resourceProgressBarSection={
          <ResourceProgressBar
            bandWidthUsed={Math.round(
              ((aiTokenCountUsed ?? 0) / aiTokenCountTotal) * 100
            )}
            indicator={colors.aiStorage[500]}
            colorScheme={"aiStorage"}
          />
        }
        purchaseExtraSection={
          <Flex pt="17px" w="100%" gap="8px">
            <Menu>
              <MenuButton
                color="black"
                border="1px solid"
                borderColor="#C2C2C2"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                fontSize="12px"
                h="35px"
                minW="178px"
                px="23px"
              >
                {storage}K Pages - ${AI_STORAGE_PRICES[storage] / 100}
              </MenuButton>
              <MenuList>
                {Object.entries(AI_STORAGE_PRICES).map(([nPages, price]) => (
                  <MenuItem
                    key={nPages}
                    onClick={() => setStorage(Number(nPages))}
                  >
                    {nPages}K Pages - ${price / 100}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Button
              maxW="71px"
              color="white"
              bg="primary"
              fontSize="12px"
              h="35px"
              onClick={async () => {
                await initiateStoragePurchase(storage);
              }}
              isLoading={isInitiatingStoragePurchase}
            >
              Buy
            </Button>
          </Flex>
        }
      />
    );
  }

  return (
    <Flex gap="36px">
      {AiCreditsCard}
      {customization.resources.disableStorageIndicator ? null : AiStorageCard}
    </Flex>
  );
};
