import { Avatar, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { GetUserNameAndPhotoUrlResponse } from "shared/apiTypes";

export const AuthorNameAndPhoto = ({
  authorData,
  authorLoading,
  authorFetchingError,
}: {
  authorData: GetUserNameAndPhotoUrlResponse | undefined;
  authorLoading: boolean;
  authorFetchingError: boolean;
}) => {
  if (!authorData) {
    return null;
  }

  const { photoURL, displayName } = authorData;
  if (authorLoading || authorFetchingError) {
    return null;
  }
  return (
    <Flex gap="8px">
      <Avatar boxSize="12px" src={photoURL} />
      <Text fontSize="9px" fontStyle="italic" color="black" opacity="0.54">
        Added by {displayName}
      </Text>
    </Flex>
  );
};

export const AddedByAvatar = ({
  authorData,
}: {
  authorData: GetUserNameAndPhotoUrlResponse | undefined;
}) => {
  if (!authorData) {
    return null;
  }
  const { photoURL, displayName } = authorData;
  return (
    <Tooltip label={displayName}>
      <Avatar
        boxSize="16px"
        src={photoURL}
        name={displayName}
        sx={{
          "& .chakra-avatar__initials": {
            fontSize: "8px",
          },
        }}
      />
    </Tooltip>
  );
};
