import { BsTable } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { ArticleType } from "shared/articleTypes";
import AudioIcon from "src/Components/Icons/AudioIcon";
import DocxIcon from "src/Components/Icons/DocxIcon";
import PdfIconComponent from "src/Components/Icons/PdfIconComponent";
import SlidesIcon from "src/Components/Icons/SlidesIcon";
import { PdfIcon } from "../Components/Icons/PdfIcons";
import WebIconComponent from "../Components/Icons/WebIconComponent";
import { VIEW_MODE_MAP } from "./ViewModeData";

export const WHITELIST = [
  "https://docs.google.com/",
  "https://drive.google.com/",
  "https://mail.google.com/",
  "https://www.youtube.com/",
  "https://www.hubspot.com/",
  "https://mailchimp.com/",
  "https://github.com/",
  // 'https://www.linkedin.com/',
  "https://www.facebook.com/",
  "https://twitter.com/",
  "https://www.instagram.com/",
  "https://www.pinterest.com/",
  "https://www.reddit.com/",
  "https://about.gitlab.com/",
  "https://www.canva.com/",
  "https://www.figma.com/",
  "https://www.sketch.com/",
  "https://www.invisionapp.com/",
  "https://www.adobe.com/",
  "https://www.photoshop.com/",
  "https://www.affinity.co/",
  "https://evernote.com/",
  "https://www.notion.so/",
  "https://www.trello.com/",
  "https://www.asana.com/",
  "https://www.monday.com/",
  "https://www.clickup.com/",
  "https://www.atlassian.com/",
  "https://slack.com/",
  "https://www.salesforce.com/",
  "https://www.dropbox.com/",
  "https://www.box.com/",
  "https://www.google.com/",
  "https://www.bing.com/",
  "https://www.yahoo.com/",
  "https://onedrive.live.com/",
  "https://www.icloud.com/",
  "https://www.amazon.com/",
  "https://www.ebay.com/",
  "https://microsoft.sharepoint.com/",
  "https://www.onenote.com/",
];

export const isUrlInWhitelist = (url, whitelist) => {
  const baseUrl = new URL(url).origin;
  console.log(`Checking if ${url} is in whitelist, base url is ${baseUrl}`);
  for (let i = 0; i < whitelist.length; i++) {
    if (whitelist[i].includes(baseUrl)) {
      console.log(`Found ${url} in whitelist`);
      return true;
    }
  }
  return false;
};

// options for what is opened when article title is clicked on the project page
export const TITLE_OPENS = {
  url: "url",
  viewer: "viewer",
};

export const ACTIONS = {
  AI_INSIGHT: "AI_INSIGHT",
  OPEN_VIEWER: "OPEN_VIEWER",
  COMMENT: "COMMENT",
  GO_TO_LINK: "GO_TO_LINK",
  DOWNLOAD: "DOWNLOAD",
};

export const VIEW_FILE_FUNCTIONS = {
  DOWNLOAD: "DOWNLOAD",
  VIEWER: "VIEWER",
  OPEN_LINK: "OPEN_LINK",
};

const defaultArticleType: ArticleTypeData = {
  uiName: "Unknown",
  uiNamePlural: "Unknown",
  insightTypes: [],
  actions: [],
  requestFromVectorDb: false,
  sortOrder: 999,
};

interface ArticleTypeData {
  /** The singular name of the article type to be displayed in the UI */
  uiName: string;
  /** The plural name of the article type to be displayed in the UI */
  uiNamePlural: string;
  /** Types of insights that can be generated for this article type */
  insightTypes: string[];
  /** Available actions for this article type */
  actions: string[];
  /** Whether to request data from the vector database for this article type when used in the assistant */
  requestFromVectorDb: boolean;
  /** Determines what happens when the article title is clicked (either opens URL or viewer) */
  titleOpens?: string;
  /** Icon component to be used for this article type */
  icon?: React.ElementType;
  /** Default view mode for the insight card */
  defaultInsightCardMode?: string;
  /** File type of the article (e.g., "pdf", "docx"). Remember that article type do not always
   * match the file type. For example, intelqVerbatim and intelqQna are of fileType `pdf`.
   * Think of this field as the "extension" of the file as it is stored in the file storage,
   * while article type itself defines how the article is processed, used in the application, etc. */
  fileType?: string;
  /** Function to use for viewing the file (e.g., "VIEWER", "DOWNLOAD", "OPEN_LINK") */
  viewFileFunction?: string;
  /** Field name containing the PDF file path or URL */
  viewFilePdfField?: string;
  /** Text to display on the download button */
  downloadBttnTxt?: string;
  /** Whether to skip indexing for this article type */
  skipIndexing?: boolean;
  /** Whether to hide the "Mark as Link" option for this article type */
  hideMarkAsLink?: boolean;
  /** Tooltip text to display for this article type */
  toolTip?: string;
  /** Order of the article type in sorting of the project page table */
  sortOrder: number;
}

export const ARTICLE_TYPE_DATA: Record<ArticleType, ArticleTypeData> =
  new Proxy<Record<ArticleType, ArticleTypeData>>(
    {
      pdf: {
        uiName: "PDF",
        uiNamePlural: "PDFs",
        insightTypes: ["summary", "keyPoint"],
        actions: [
          ACTIONS.AI_INSIGHT,
          ACTIONS.OPEN_VIEWER,
          ACTIONS.COMMENT,
          ACTIONS.GO_TO_LINK,
          ACTIONS.DOWNLOAD,
        ],
        requestFromVectorDb: true,
        titleOpens: TITLE_OPENS.viewer,
        icon: PdfIcon,
        defaultInsightCardMode: VIEW_MODE_MAP.comfort.id,
        fileType: "pdf",
        viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
        viewFilePdfField: "url",
        downloadBttnTxt: "Download",
        sortOrder: 2,
      },
      html: {
        uiName: "Web article",
        uiNamePlural: "Web articles",
        insightTypes: ["summary", "keyPoint"],
        actions: [ACTIONS.AI_INSIGHT, ACTIONS.COMMENT, ACTIONS.GO_TO_LINK],
        requestFromVectorDb: true,
        titleOpens: TITLE_OPENS.viewer,
        icon: WebIconComponent,
        viewFileFunction: VIEW_FILE_FUNCTIONS.OPEN_LINK,
        sortOrder: 1,
      },
      supportingLink: {
        uiName: "Link",
        uiNamePlural: "Links",
        insightTypes: [],
        actions: [ACTIONS.COMMENT, ACTIONS.GO_TO_LINK],
        requestFromVectorDb: false,
        titleOpens: TITLE_OPENS.url,
        icon: IoDocumentTextOutline,
        skipIndexing: true,
        hideMarkAsLink: true,
        toolTip: `Links cannot be accessed by AI Assistant. You can use the "Text" option in Add Content menu to copy-paste the link content manually.`,
        viewFileFunction: VIEW_FILE_FUNCTIONS.OPEN_LINK,
        sortOrder: 100,
      },
      youtube: {
        uiName: "Youtube video",
        uiNamePlural: "Youtube videos",
        insightTypes: ["summary", "keyPoint"],
        actions: [ACTIONS.AI_INSIGHT, ACTIONS.COMMENT, ACTIONS.GO_TO_LINK],
        requestFromVectorDb: true,
        titleOpens: TITLE_OPENS.viewer,
        icon: FaYoutube,
        sortOrder: 11,
        viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
        viewFilePdfField: "pathToPdf",
      },
      table: {
        uiName: "Table",
        uiNamePlural: "Tables",
        insightTypes: ["summary", "keyPoint"],
        actions: [ACTIONS.AI_INSIGHT, ACTIONS.COMMENT, ACTIONS.GO_TO_LINK],
        requestFromVectorDb: true,
        titleOpens: TITLE_OPENS.viewer,
        icon: BsTable,
        viewFileFunction: VIEW_FILE_FUNCTIONS.DOWNLOAD,
        sortOrder: 5,
      },
      voice: {
        uiName: "Voice recording",
        uiNamePlural: "Voice recordings",
        insightTypes: ["summary", "keyPoint"],
        actions: [
          ACTIONS.AI_INSIGHT,
          ACTIONS.OPEN_VIEWER,
          ACTIONS.COMMENT,
          ACTIONS.GO_TO_LINK,
          ACTIONS.DOWNLOAD,
        ],
        requestFromVectorDb: true,
        titleOpens: TITLE_OPENS.viewer,
        icon: AudioIcon,
        viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
        viewFilePdfField: "pathToPdf",
        downloadBttnTxt: "Download transcript",
        sortOrder: 6,
      },
      docx: {
        uiName: "Docx",
        uiNamePlural: "Docx",
        insightTypes: ["summary", "keyPoint"],
        actions: [ACTIONS.AI_INSIGHT, ACTIONS.COMMENT, ACTIONS.GO_TO_LINK],
        requestFromVectorDb: true,
        titleOpens: TITLE_OPENS.viewer,
        icon: DocxIcon,
        viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
        viewFilePdfField: "pathToPdf",
        sortOrder: 3,
      },
      pptx: {
        uiName: "PPTX",
        uiNamePlural: "PPTX",
        insightTypes: ["summary", "keyPoint"],
        actions: [ACTIONS.AI_INSIGHT, ACTIONS.COMMENT, ACTIONS.GO_TO_LINK],
        requestFromVectorDb: true,
        titleOpens: TITLE_OPENS.viewer,
        icon: SlidesIcon,
        viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
        viewFilePdfField: "pathToPdf",
        sortOrder: 4,
      },
      intelqVerbatim: {
        uiName: "Statement",
        uiNamePlural: "Statements",
        insightTypes: ["summary", "keyPoint"],
        actions: [
          ACTIONS.AI_INSIGHT,
          ACTIONS.OPEN_VIEWER,
          ACTIONS.COMMENT,
          ACTIONS.GO_TO_LINK,
          ACTIONS.DOWNLOAD,
        ],
        requestFromVectorDb: true,
        titleOpens: TITLE_OPENS.viewer,
        fileType: "pdf",
        icon: PdfIconComponent,
        viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
        viewFilePdfField: "url",
        downloadBttnTxt: "Download",
        sortOrder: 7,
      },
      intelqQna: {
        uiName: "Q&A",
        uiNamePlural: "Q&As",
        insightTypes: ["summary", "keyPoint"],
        actions: [
          ACTIONS.AI_INSIGHT,
          ACTIONS.OPEN_VIEWER,
          ACTIONS.COMMENT,
          ACTIONS.GO_TO_LINK,
          ACTIONS.DOWNLOAD,
        ],
        requestFromVectorDb: true,
        titleOpens: TITLE_OPENS.viewer,
        fileType: "pdf",
        icon: PdfIconComponent,
        viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
        viewFilePdfField: "url",
        downloadBttnTxt: "Download",
        sortOrder: 8,
      },
      plainText: {
        uiName: "Text file",
        uiNamePlural: "Text files",
        insightTypes: ["summary", "keyPoint"],
        actions: [ACTIONS.AI_INSIGHT, ACTIONS.COMMENT, ACTIONS.OPEN_VIEWER],
        requestFromVectorDb: true,
        titleOpens: TITLE_OPENS.viewer,
        icon: IoDocumentTextOutline,
        viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
        viewFilePdfField: "pathToPdf",
        sortOrder: 9,
      },
      smartImage: {
        uiName: "Smart Image",
        uiNamePlural: "Smart Images",
        insightTypes: ["summary", "keyPoint"],
        actions: [
          ACTIONS.AI_INSIGHT,
          ACTIONS.COMMENT,
          ACTIONS.GO_TO_LINK,
          ACTIONS.OPEN_VIEWER,
        ],
        requestFromVectorDb: true,
        titleOpens: TITLE_OPENS.viewer,
        icon: IoDocumentTextOutline,
        viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
        viewFilePdfField: "pathToPdf",
        sortOrder: 10,
      },
    },
    {
      get: function (
        target: Record<ArticleType, ArticleTypeData>,
        name: PropertyKey
      ): ArticleTypeData {
        if (!(name in target)) {
          console.error(`Article type ${String(name)} not found`);
          return defaultArticleType;
        }
        return target[name as ArticleType];
      },
    }
  );
