import { Box, Flex, HStack, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../Auth/AuthProvider";
import { useArticle } from "../../Context/ArticleContext";
import { useAssistantContext } from "../../Context/AssistantContext";
import useNotification from "../../hooks/useNotification";

import { addDoc, collection } from "firebase/firestore";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import AssistantChatInput from "../assistant/AssistantChatInput";
import {
  addMessageToDb,
  manageSelectedArticles,
} from "../assistant/AssistantChatWindow";
import { ChatToArticleOnAssistantMenu } from "../assistant/ChatToArticleOnAssistantMenu";
import { AiModulesList } from "../Home/AIModules/AiModulesList";

import { useCreateAiModuleRequest } from "src/hooks/useCreateAiModuleRequest";
import { InputPopup } from "./InputPopup";

/**
 * Replaces placeholders in a string with corresponding values from a replacements object.
 *
 * @param {string} str - The string containing placeholders to be replaced.
 * @param {Object} replacements - An object containing key-value pairs where the key represents the placeholder and the value represents the replacement.
 * @returns {string} - The formatted string with replaced placeholders.
 */
function formatString(str, replacements) {
  return str.replace(/{{(\w+)}}/g, function (_, key) {
    return replacements[key];
  });
}

export function clearVariables(str) {
  return str.replace(/{{(\w+)}}/g, "");
}

const AiModulesInHeader = ({ articles }) => {
  const { mutateAsync: createPromptRequest, error } =
    useCreateAiModuleRequest();
  const { notify } = useNotification();
  const { setConversationId, setSubscribeToConversationDoc, setViewMode } =
    useAssistantContext();
  const { currentUser } = useAuth();
  const { projectId } = useParams();
  const { selectedArticleIds, selectedArticlesData } = useArticle();

  const [inputPopupValue, setInputPopupValue] = React.useState("");
  const [variableData, setVariableData] = React.useState({
    name: "",
    optional: false,
  });
  const [variableKey, setVariableKey] = React.useState("");
  const [prompt, setPrompt] = React.useState(null);
  const {
    isOpen: isOpenInputPopup,
    onOpen: openInputPopup,
    onClose,
  } = useDisclosure();

  if (error) {
    notify({
      title: "Error",
      description: "Failed to start. Please try again later.",
      status: "error",
    });
    console.error(error);
  }

  const sendCustomPrompt = async ({ prompt, variableValueMap }) => {
    let message;
    // if there is an input value
    if (variableValueMap) {
      const { variableKey, value } = variableValueMap;
      message = {
        role: "user",
        content: formatString(prompt.userMessage, {
          [variableKey]: value,
        }),
        createdAt: Date.now(),
        title: prompt.title,
        type: "custom",
        inputValue: formatString(prompt.userMessage, {
          [variableKey]: value,
        }),
      };
      // setInputPopupValue("");
    } else {
      message = {
        role: "user",
        content: clearVariables(prompt.userMessage),
        createdAt: Date.now(),
        title: prompt.title,
        type: "custom",
        inputValue: clearVariables(prompt.userMessage),
      };
    }
    // TODO: logic for selecting articles is scattered across the app;
    // refactor to a handle in a single place.

    if (selectedArticleIds.length === 0) {
      manageSelectedArticles(message, articles);
    } else {
      const selectedArticles = articles.filter((article) =>
        selectedArticleIds.includes(article.uid)
      );
      message.selectedArticles = selectedArticles;
      message.selectedArticleQuery = true;
    }

    const conversationId = uuidv4();
    await addMessageToDb({
      projectId,
      conversationId,
      userId: currentUser.uid,
      message,
      createdAt: Date.now(),
    });

    if (prompt.api_version === 1) {
      const invocationPayload = {
        userId: currentUser.uid,
        projectId,
        conversationId,
        aiModuleId: prompt.uid,
        variables: {
          requirements: {
            client_input: {
              $project_id: projectId,
              $conversation_id: conversationId,
              $selected_articles: message.selectedArticles,
            },
            user_input: {},
          },
        },
      };
      if (variableValueMap) {
        const { variableKey, value } = variableValueMap;
        if (value) {
          invocationPayload.variables.requirements.user_input[variableKey] =
            value;
        } else if (variableData.optional) {
          invocationPayload.variables.requirements.user_input[variableKey] = "";
        } else {
          notify({
            title: "Error",
            description: `Value for ${variableData.name.toLowerCase()} is required.`,
            status: "error",
          });
          return;
        }
      }
      addDoc(collection(db, "aiModuleInvocations"), invocationPayload);
    } else {
      await createPromptRequest({
        module: prompt,
        conversationId,
        projectId,
      });
    }

    setConversationId(conversationId);
    setSubscribeToConversationDoc(true);
    setViewMode("CHAT");
  };

  function handleStartCustomPrompt(prompt) {
    return async () => {
      setPrompt(prompt);
      if (prompt.variables) {
        // check if there are more than one variable in `variables` object -- not supported for now
        const variableKeys = Object.keys(prompt.variables);
        if (variableKeys.length === 0) {
          // no variables, just exit the if block
        } else if (variableKeys.length > 1) {
          notify({
            title: "Error",
            description: "Multiple variables are not supported.",
            status: "error",
          });
          return;
        } else {
          const variableKey = variableKeys[0];
          const variable = prompt.variables[variableKey];
          // check if input value is empty
          if (typeof variable.optional === "boolean") {
            setPrompt(prompt);
            setVariableKey(variableKey);
            setVariableData({
              name: variable.description,
              optional: variable.optional,
            });
            openInputPopup();
            return;
          }
        }
      }
      await sendCustomPrompt({ prompt });
    };
  }

  const handlePopUpAction = async () => {
    if (!inputPopupValue && !variableData.optional) {
      notify({
        title: "Error",
        description: `Value for ${variableData.name.toLowerCase()} is required.`,
        status: "error",
      });
      return;
    }
    await sendCustomPrompt({
      prompt,
      variableValueMap: {
        variableKey,
        value: inputPopupValue,
      },
    });
    onClose();
    setVariableKey("");
    setVariableData({ name: "", optional: false });
    setPrompt(null);
    setInputPopupValue("");
  };

  const handleCloseInputPopup = () => {
    setVariableKey("");
    setVariableData({ name: "", optional: false });
    setPrompt(null);
    setInputPopupValue("");
    setInputPopupValue("");
    onClose();
  };

  return (
    <Box w="full" position="relative" h="full">
      <InputPopup
        isOpen={isOpenInputPopup}
        onClose={handleCloseInputPopup}
        variableKey={variableKey}
        action={handlePopUpAction}
        input={inputPopupValue}
        setInput={setInputPopupValue}
        body={`Please enter ${
          variableData.name?.charAt(0).toLowerCase() +
          variableData.name?.slice(1)
        }:`}
        optional={variableData.optional}
      />
      <Flex direction="column" h="full" pb={12}>
        <Flex
          direction={"column"}
          flex="1"
          rounded="md"
          overflowY="auto"
          pt={"16px"}
          pb={5}
          px="8px"
          bg="#FAFAFA"
          className="webkit-scrollbar-display-none"
        >
          <Flex flexDir="column" pos="relative">
            <HStack
              gap="7px"
              alignItems="center"
              position="absolute"
              right="10px"
              top="0px"
              as={Link}
              to="/user?tab=custom-ai"
            >
              <IoSettingsOutline fontSize="14px" />
              <Text fontSize="10px" fontWeight="bold">
                Modules
              </Text>
            </HStack>
            <AiModulesList
              action="SEND"
              onClick={(prompt) => {
                handleStartCustomPrompt(prompt)();
              }}
            />
          </Flex>
        </Flex>
        <Flex h="12px" />
        <Flex direction="column" bg="white">
          {selectedArticlesData.length > 0 ? (
            <Flex pb="12px">
              <ChatToArticleOnAssistantMenu />
            </Flex>
          ) : null}
          <AssistantChatInput
            assistantDisabled={true}
            assistantIsGenerating={false}
            handleSendClick={() => {}}
            inputFieldPlaceholderText={
              "Additional input for AI Module (optional)"
            }
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default AiModulesInHeader;
