import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import useAiModules from "src/hooks/useAiModules";
import { AiModuleBox, CustomPromptListGrid } from "../AiModules/AiModuleBox";

export const LibraryPageTopRatedCustomAIModulesLists = () => {
  const { modules } = useAiModules();
  if (!modules) return null;
  const { topRatedModules } = modules;

  if (topRatedModules.length === 0) {
    return null;
  }

  return (
    <Flex mt="22px" flexDir="column" gap="16px">
      <Text fontSize="12px" color="primary">
        Top rated
      </Text>
      <CustomPromptListGrid>
        {topRatedModules.map((prompt) => {
          return (
            <AiModuleBox
              key={prompt.uid}
              moduleData={prompt}
              isLoading={false}
              action={prompt.inUserLibrary ? "ALREADY_ADDED_INDICATOR" : "ADD"}
              actionAlwaysOn={true}
              actionOnlyOnIcon={true}
            />
          );
        })}
      </CustomPromptListGrid>
    </Flex>
  );
};
