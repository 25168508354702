import { useMutation } from "@tanstack/react-query";
import { addDoc, collection } from "firebase/firestore";
import { getCurrentUser } from "../Auth/AuthProvider";
import { db } from "../firebase";

export const createAiModuleRequest = async ({
  conversationId,
  module,
  projectId,
}) => {
  const currentUser = getCurrentUser();
  await addDoc(collection(db, "customPromptChatRequest"), {
    promptId: module.uid,
    title: module.title,
    description: module.description,
    timestamp: Date.now() / 1000,
    userId: currentUser.uid,
    conversationId,
    projectId,
  });
};

export const useCreateAiModuleRequest = () =>
  useMutation({
    mutationFn: createAiModuleRequest,
  });
